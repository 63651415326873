<template>
  <div class="partners">
    <div class="partner-list">
      <div class="partner-list__ellipsis">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div class="partner-list__column">
        <div class="partner-list__item partner-1" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">广东广电网络</a>
        </div>
        <div class="partner-list__item partner-2" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">中国互联网电视</a>
        </div>
        <div class="partner-list__item partner-3" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">咪咕</a>
        </div>
        <div class="partner-list__item partner-4" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">云视听</a>
        </div>
      </div>

      <div class="partner-list__column">
        <div class="partner-list__item partner-5" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">Hisense</a>
        </div>
        <div class="partner-list__item partner-6" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">重庆有线</a>
        </div>
        <div class="partner-list__item partner-7" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">CIBN</a>
        </div>
        <div class="partner-list__item partner-8" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">百视通</a>
        </div>
      </div>

      <div class="partner-list__column">
        <div class="partner-list__item partner-9" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">江苏有线</a>
        </div>
        <div class="partner-list__item partner-10" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">康佳</a>
        </div>
        <div class="partner-list__item partner-11" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">PHILIPS</a>
        </div>
        <div class="partner-list__item partner-12" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">创维</a>
        </div>
      </div>

      <div class="partner-list__column">
        <div class="partner-list__item partner-13" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">电视猫</a>
        </div>
        <div class="partner-list__item partner-14" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">芒果TV</a>
        </div>
        <div class="partner-list__item partner-15" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">SNM</a>
        </div>
        <div class="partner-list__item partner-16" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">华数TV</a>
        </div>
      </div>

      <div class="partner-list__column">
        <div class="partner-list__item partner-17" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">优酷</a>
        </div>
        <div class="partner-list__item partner-18" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">小米</a>
        </div>
        <div class="partner-list__item partner-19" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">爱奇艺OTT</a>
        </div>
        <div class="partner-list__item partner-20" @mouseenter="enlargeLogo" @mouseleave="resetLogo">
          <a href="#">乐融</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  TweenMax,
  CSSPlugin,
  AttrPlugin,
}  from "gsap/all";

const plugins = [
  TweenMax,
  CSSPlugin,
  AttrPlugin
];
export default {
  methods: {
    enlargeLogo(e) {
      const logoIndex = e.target.classList.item(1).split('-')[1];
      const rowNumber = logoIndex % 4;

      const $els = $(this.$el).find('.partner-list__item').filter(function(index, el) {
        return el.classList.item(1).split('-')[1] % 4 == rowNumber
      });

      TweenMax.to($els, .35, { filter: 'none', scale: 1.25});
        
    },
    resetLogo(e) {
      const logoIndex = e.target.classList.item(1).split('-')[1];
      const rowNumber = logoIndex % 4;

      const $els = $(this.$el).find('.partner-list__item').filter(function(index, el) {
        return el.classList.item(1).split('-')[1] % 4 == rowNumber
      });

      TweenMax.to($els, .35, { filter: 'grayscale(100%)', scale: 1});
    }
  } 
}
</script>

<style lang="postcss">
.partners {
  position: absolute;
  width: 100%;
  height: 100%;
}
.partner-list {
  position: absolute;
  top: 25%;
  left: 8.28%;
  right: 8.28%;
  bottom: 25%;

  display: flex;

  &__ellipsis {
    position: absolute;
    width: calc(8 * 100vw / 1920);
    height: calc(41 * 100vw / 1920);
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 64 * 100vw / 1920);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      width: calc(8 * 100vw / 1920);
      height: calc(8 * 100vw / 1920);
      border-radius: 50%;
      background: var(--primary-black);
    }
  }


  &__column {
    width: 20%;
    height: 100%;

    background: #fff;

    display: flex;
    flex-direction: column;

    justify-content: space-between;

    &:not(:first-of-type) {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        height: 59.25%;
        width: 1px;
        left: -.5px;
        top: 50%;
        transform: translateY(-50%);
        background: #f1f1f1;
      }
    }

    /* filter: grayscale(100%);

    transition: filter .5s , transform .5s; */

    /* &:hover {
      filter: none;
      transform: scale(1.1);
    } */
  }

  &__item {
    
    flex: 0 0 6.25vw;
    
    width: 100%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    filter: grayscale(100%);

    & > a {
      display: block;
      width: 62.5%;
      height: 100%;
      text-indent: -9999px;
    }
  }
}

/* @for $i from 1 to 20 {
  .partner-#{$i} {
    background: url('../assets/partner-#{$i}.png') no-repeat center;
    background-size: contain;
  }
} */

.partner-1 {
  background: url('../assets/partner-1.png') no-repeat center;
  background-size: contain;
}
.partner-2 {
  background: url('../assets/partner-2.png') no-repeat center;
  background-size: contain;
}
.partner-3 {
  background: url('../assets/partner-3.png') no-repeat center;
  background-size: contain;
}
.partner-4 {
  background: url('../assets/partner-4.png') no-repeat center;
  background-size: contain;
}

.partner-5 {
  background: url('../assets/partner-5.png') no-repeat center;
  background-size: contain;
}
.partner-6 {
  background: url('../assets/partner-6.png') no-repeat center;
  background-size: contain;
}
.partner-7 {
  background: url('../assets/partner-7.png') no-repeat center;
  background-size: contain;
}
.partner-8 {
  background: url('../assets/partner-8.png') no-repeat center;
  background-size: contain;
}

.partner-9 {
  background: url('../assets/partner-9.png') no-repeat center;
  background-size: contain;
}
.partner-10 {
  background: url('../assets/partner-10.png') no-repeat center;
  background-size: contain;
}
.partner-11 {
  background: url('../assets/partner-11.png') no-repeat center;
  background-size: contain;
}
.partner-12 {
  background: url('../assets/partner-12.png') no-repeat center;
  background-size: contain;
}

.partner-13 {
  background: url('../assets/partner-13.png') no-repeat center;
  background-size: contain;
}
.partner-14 {
  background: url('../assets/partner-14.png') no-repeat center;
  background-size: contain;
}
.partner-15 {
  background: url('../assets/partner-15.png') no-repeat center;
  background-size: contain;
}
.partner-16 {
  background: url('../assets/partner-16.png') no-repeat center;
  background-size: contain;
}

.partner-17 {
  background: url('../assets/partner-17.png') no-repeat center;
  background-size: contain;
}
.partner-18 {
  background: url('../assets/partner-18.png') no-repeat center;
  background-size: contain;
}
.partner-19 {
  background: url('../assets/partner-19.png') no-repeat center;
  background-size: contain;
}
.partner-20 {
  background: url('../assets/partner-20.png') no-repeat center;
  background-size: contain;
}
</style>