<template>
  <div class="intro">

    <svg>
      <defs>
        <clipPath id="button-clip" clipPathUnits="objectBoundingBox">
          <path d="M0.0290679967,0 C0.00968933223,0.166666667 -1.33226763e-15,0.333333333 -1.33226763e-15,0.5 C-1.33226763e-15,0.666666667 0.00968933223,0.833333333 0.0290679967,1 L1,1 L1,0 L0.0290679967,0 Z" id="Path" transform="translate(0.500000, 0.500000) scale(-1, 1) rotate(-360.000000) translate(-0.500000, -0.500000) "></path>
        </clipPath>
      </defs>
    </svg>


    <div class="goal-wrap">
      <article class="goal">
        <section>
          <header class="goal__title">{{ solution_1_title }}</header>
          <header class="goal__sub-title">
            
            <div>
              <svg viewBox="0 0 37 87">
                <use class="goal__sub-title-unit" xlink:href="#logo-frag-3"></use>
              </svg>
            </div>
            <span>{{ solution_1_subtitle }}</span>
          </header>
          <p class="goal__description">
            {{ solution_1_description }}
          </p>
        </section>
        
      </article>

      <article class="goal">
        <section>
          <header class="goal__title">{{ solution_2_title }}</header>
          <header class="goal__sub-title">
            
            <div>
              <svg viewBox="0 0 37 87">
                <use class="goal__sub-title-unit" xlink:href="#logo-frag-3"></use>
              </svg>
            </div>
            <span>{{ solution_2_subtitle }}</span> 
          </header>
          <p class="goal__description">
            {{ solution_2_description }}
          </p>
        </section>
        
      </article>
      
    </div>

    <div class="intro-divider"></div>

    <div class="platform">
      <div class="platform__main">
        <div class="platform__title">{{ platform_title }}</div>
        <a href="#" class="platform__enter" @click.prevent="linkPlatform">
          <span class="platform__enter-text">前往平台</span>
          <span class="platform__enter-deco">
            <div>
              <svg viewBox="0 0 41 87">
                <use class="platform__enter-deco-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </div>
            <div>
              <svg viewBox="0 0 41 87">
                <use class="platform__enter-deco-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </div>
            <div>
              <svg viewBox="0 0 41 87">
                <use class="platform__enter-deco-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </div>
            <div>
              <svg viewBox="0 0 41 87">
                <use class="platform__enter-deco-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </div>
          </span>
        </a>
      </div>

      <div class="platform__product">
        <div class="platform__row">
          <div class="platform__column">
            <img :src="platform_feature_1_thumbnail" alt="">
            <span class="platform__column-text">{{ platform_feature_1_title }}</span>
          </div>
          <div class="platform__column">
            <img :src="platform_feature_2_thumbnail" alt="">
            <span class="platform__column-text">{{ platform_feature_2_title }}</span>
          </div>
        </div>
        <div class="platform__row">
          <div class="platform__column">
            <img :src="platform_feature_3_thumbnail" alt="">
            <span class="platform__column-text">{{ platform_feature_3_title }}</span>
          </div>
          <div class="platform__column">
            <img :src="platform_feature_4_thumbnail" alt="">
            <span class="platform__column-text">{{ platform_feature_4_title }}</span>
          </div>
        </div>
      </div>

    </div>

    <div class="platform-data">
        <div class="platform-data__entity">
          <div class="platform-data__label">{{ data1.label }}</div>
          <div class="platform-data__value">{{ data1.value }}</div>
        </div>
        <div class="platfor-data__entity">
          <div class="platform-data__label">{{ data2.label }}</div>
          <div class="platform-data__value">{{ data2.value }}</div>
        </div>
      </div>

  </div>
</template>

<script>
import {
  CSSPlugin,
  AttrPlugin,
  TimelineMax
}  from "gsap/all";

const plugins = [ CSSPlugin, AttrPlugin ];
export default {
  data() {
    return {
      solution_1_title: '',
      solution_1_subtitle: '',
      solution_1_description: '',
      solution_2_title: '',
      solution_2_subtitle: '',
      solution_2_description: '',
      platform_title: '',
      platform_feature_1_title: '',
      platform_feature_1_thumbnail: '',
      platform_feature_2_title: '',
      platform_feature_2_thumbnail: '',
      platform_feature_3_title: '',
      platform_feature_3_thumbnail: '',
      platform_feature_4_title: '',
      platform_feature_4_thumbnail: '',

      data1: {
        label: '',
        value:''
      },
      data2: {
        label: '',
        value: ''
      }
    }
  },
  methods: {
    linkPlatform() {
      window.location.href = 'https://e.trendimedia.com';
    }
  },
  created() {
    const propHost = this.$root.$children[0];
    
    this.data1.label = propHost.product_data_1_label;
    this.data1.value = propHost.product_data_1_value;
    this.data2.label = propHost.product_data_2_label;
    this.data2.value = propHost.product_data_2_value;

    this.solution_1_title = propHost.solution_1_title;
    this.solution_1_subtitle = propHost.solution_1_subtitle;
    this.solution_1_description = propHost.solution_1_description;
    this.solution_2_title = propHost.solution_2_title;
    this.solution_2_subtitle = propHost.solution_2_subtitle;
    this.solution_2_description = propHost.solution_2_description;

    this.platform_title = propHost.platform_title;
    this.platform_feature_1_title = propHost.platform_feature_1_title;
    this.platform_feature_1_thumbnail = propHost.platform_feature_1_thumbnail;
    this.platform_feature_2_title = propHost.platform_feature_2_title;
    this.platform_feature_2_thumbnail = propHost.platform_feature_2_thumbnail;
    this.platform_feature_3_title = propHost.platform_feature_3_title;
    this.platform_feature_3_thumbnail = propHost.platform_feature_3_thumbnail;
    this.platform_feature_4_title = propHost.platform_feature_4_title;
    this.platform_feature_4_thumbnail = propHost.platform_feature_4_thumbnail;
    
    

    
  },

  mounted() {
    const tl = new TimelineMax({
      repeat: -1
    });

    tl
      .staggerFromTo($('use.platform__enter-deco-unit').toArray(), .3, {
        autoAlpha: 0,
      }, {
        autoAlpha: 1
      }, .2)

    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome) {
      $(this.$el.querySelector('.platform__enter')).addClass('platform__enter--clip');
    }

  }
}
</script>

<style lang="postcss">
.intro {
  position: absolute;
  width: 100%;
  height: 100%;
}
.goal-wrap {
  position: absolute;
  /* top: 16.94%; */
  top: 12.5%;
  left: 4.44%;
  width: 45.55%;  
  display: flex;
  justify-content: space-between;

}

.goal {
  width: 43.92%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--primary-black);
  
  &__title {
    
    font-size: calc(36 * 100vw / 1920);
    color: #000;
    font-weight: bold;
    margin-bottom: calc(18 * 100vw / 1920);
  }
  &__sub-title {
    line-height: 100%;
    font-size: calc(24 * 100vw / 1920);
    font-weight: bold;

    display: flex;
    align-items: center;

    & > div {
      width: calc(6 * 100vw / 1920);
      height: calc(14 * 100vw / 1920);
      margin-right: calc(12 * 100vw / 1920);

      display: inline-block;
      position: relative;


      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }
    }
    
    
  }
  &__description {
    margin-top: calc(74 * 100vw / 1920);
    /* font-size: calc(14 * 100vw / 1920); */
    /* line-height: calc(28 * 100vw / 1920); */
    font-size: calc(16 * 100vw / 1920);
    line-height: calc(30 * 100vw / 1920);
    position: relative;

    &::before,
    &::after {
      position: absolute;
      content: '';
      
      width: calc(24 * 100vw / 1920);
      height: calc(22 * 100vw / 1920);
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAMAAADto6y6AAAANlBMVEVHcEz09PT19fX39/f19fX19fX5+fn19fX19fX29vb19fX19fX19fXv7+/09PT19fX19fX09PT5QUc1AAAAEXRSTlMA8HFGzrkYhONW3iuWCjqsfhiqTkIAAAB/SURBVCjP3Y9hAoUgCIPJUisz2/0vWzik3hXefu2DIShCnXGWoXrFYVMDlgFhAiy1PRbYmInqmcqAQ9kJmgqAQ7K6porZXUPR4Ejuj/rUZ/r1FB/IfVvjbBLfwCtqv85ubXhPLZ+QLB0S/8NtbHB6VQU+O3UQvMrXB34a+Q8bNylSGPe9O/3NAAAAAElFTkSuQmCC') no-repeat center;
      background-size: contain;
    }
    &::before {
      left: 0;
      bottom: 110%;
    }
    &::after {
      right: 0;
      top: 100%;
      transform: rotate(180deg);
    }
  }
  
}

.intro-divider {
  width: 45.55%;
  height: 1px;
  background: #e7e7e7;
  position: absolute;
  left: 4.44%;
  /* top: 55.64%; */
  top: 62.5%;
}

.platform {
  position: absolute;
  width: 45.55%;
  /* height: 20.92%; */
  left: 4.44%;
  /* top: 62.4%; */
  top: 68.5%;

  display: flex;

  &::before {
    content: '';
    position: absolute;
    top: -2.5%;
    left: 50%;
    transform: translate(-50%);
    width: 1px;
    height: calc(241 * 100vw / 1920);
    background: #E7E7E7;
  }
  
  &__main,
  &__product {
    width: 50%;
  }
  &__title {
    font-size: calc(36 * 100vw / 1920);
    font-weight: bold;
  }
  &__enter {
    margin-top: calc(84 * 100vw / 1920);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 3px solid var(--primary-black); */
    background: var(--primary-red);
    
    width: calc(296 * 100vw / 1920);
    height: calc(80 * 100vw / 1920);
    /* padding: calc(30 * 100vw / 1920); */

    
    &--clip {
      clip-path: url(#button-clip);
    }


    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;

      width: calc(296 * 2 * 100vw / 1920);
      height: calc(296 * 100vw / 1920);
      background: #f1f1f1;
      border-radius: 50%;
      right: 150%;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      transition: 2000ms 0ms all cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:hover::before {
      transform: translateY(-50%) translateX(80%);
    }

    &-text {
      font-size: calc(24 * 100vw / 1920);
      /* color: var(--primary-black); */
      color: #fff;
      font-weight: bold;
      margin-right: auto;
      margin-left: 15px;
    }


    &:hover &-deco {
      transform: translateX(25%);
    }
    &-deco {
      transition: 2000ms 0ms transform cubic-bezier(0.19, 1, 0.22, 1);

      display: flex;
      justify-content: space-between;
      width: calc(29 * 100vw / 1920);
      height: calc(12 * 100vw / 1920);

      margin-right: 30px;

      & > div {
        width: 20%;
        height: 100%;

        
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 100%;
        }  
      }
    }
  }

  &__product {
    padding-left: calc(67 * 100vw / 1920);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__row {
    display: flex;
    justify-content: space-between;
  }
  &__column {
    width: calc(150 * 100vw / 1920);
    height: calc(85 * 100vw / 1920);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img {
      width: 32.5%;
      height: 58%;
    }
    &-text {
      font-size: calc(16 * 100vw / 1920);
      color: #7b7b7b;
      font-weight: bold;
      width: 105%;
      text-align: center;
    }
  }  
  
}

.platform-data {

  width: calc(548 * 100vw / 1920);
  height: calc(70 * 100vw / 1920);
  position: absolute;
  bottom: 16.67%;
  
  right: 5.61%;
  display: flex;
  justify-content: space-between;

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(60 * 100vw / 1920);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
  }

  &__entity {
    display: flex;
    flex-direction: column;
  }
  &__label {
    font-size: calc(14 * 100vw / 1920);
    color: #fff;
    line-height: 100%;
    margin-bottom: 6.5%;
  }
  &__value {
    font-size: calc(36 * 100vw / 1920);
    color: #fff;
    font-family: 'Oxanium Regular', sans-serif;
  }
}

use.goal__sub-title-unit {
  fill: var(--primary-red);
}
use.platform__enter-deco-unit {
  fill: #fff;
}

</style>