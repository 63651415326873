<template>
  <div class="contact">

    <svg>
      <defs>
        <clipPath id="button-clip-3" clipPathUnits="objectBoundingBox">
          <path d="M0.0290679967,0 C0.00968933223,0.166666667 -1.33226763e-15,0.333333333 -1.33226763e-15,0.5 C-1.33226763e-15,0.666666667 0.00968933223,0.833333333 0.0290679967,1 L1,1 L1,0 L0.0290679967,0 Z" id="Path" transform="translate(0.500000, 0.500000) scale(-1, 1) rotate(-360.000000) translate(-0.500000, -0.500000) "></path>
        </clipPath>
      </defs>
    </svg>

    <svg style="display:none" xmlns="http://www.w3.org/2000/svg">
      <symbol id="media-icon-wechat">
        <g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><path d="M23.367 17.264c.628-.008 1.135-.502 1.143-1.112 0-.617-.508-1.112-1.143-1.112s-1.143.495-1.143 1.112c0 .619.51 1.112 1.143 1.112zm-5.632 0c.628-.008 1.135-.502 1.143-1.112 0-.617-.51-1.112-1.143-1.112-.636 0-1.143.495-1.143 1.112 0 .619.508 1.112 1.143 1.112zm8.358 6.24a.426.426 0 00-.219.459c0 .06 0 .12.032.182.125.516.374 1.339.374 1.37 0 .09.032.15.032.213 0 .072-.03.142-.082.193a.284.284 0 01-.2.079c-.063 0-.093-.03-.156-.06l-1.844-1.035a1.038 1.038 0 00-.438-.121c-.093 0-.187 0-.25.03-.875.244-1.781.365-2.75.365-4.658 0-8.409-3.043-8.409-6.817 0-3.773 3.751-6.817 8.41-6.817 4.656 0 8.407 3.044 8.407 6.817 0 2.04-1.125 3.896-2.907 5.144v-.001zm-5.146-13.19a11.91 11.91 0 00-.356-.006c-5.312 0-9.68 3.542-9.68 8.055 0 .686.102 1.35.29 1.982h-.113a13.009 13.009 0 01-3.29-.456c-.093-.031-.187-.031-.282-.031a1.08 1.08 0 00-.532.152L4.76 21.255c-.064.03-.126.062-.189.062a.343.343 0 01-.343-.335c0-.091.03-.152.062-.244.03-.03.313-1.032.47-1.64 0-.061.03-.151.03-.212a.682.682 0 00-.281-.547C2.347 16.849 1 14.632 1 12.17 1 7.647 5.544 4 11.118 4c4.792 0 8.82 2.686 9.83 6.313v.001zm-6.56.518c.73 0 1.307-.59 1.307-1.27 0-.71-.577-1.271-1.306-1.271-.73 0-1.306.56-1.306 1.27s.577 1.27 1.306 1.27zm-6.694 0c.73 0 1.307-.59 1.307-1.27 0-.71-.577-1.271-1.307-1.271-.728 0-1.306.56-1.306 1.27s.578 1.27 1.306 1.27z" fill="#FFF" fill-rule="nonzero"/></g>
      </symbol>
      <!-- <symbol id="media-icon-qq">
        <g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><path d="M24.275 17.661a40.285 40.285 0 00-.834-2.357l-1.124-2.808c0-.032.015-.585.015-.87C22.332 6.825 20.068 2 14.5 2 8.933 2 6.67 6.825 6.67 11.625c0 .286.013.839.014.871L5.56 15.304c-.306.803-.611 1.64-.834 2.357-1.062 3.421-.717 4.838-.455 4.869.562.067 2.187-2.575 2.187-2.575 0 1.53.787 3.527 2.49 4.97-.636.196-1.416.499-1.919.869-.45.333-.394.675-.313.812.357.601 6.121.384 7.787.196 1.663.188 7.429.405 7.784-.197.082-.138.139-.478-.312-.811-.503-.37-1.284-.673-1.921-.87 1.703-1.442 2.49-3.44 2.49-4.97 0 0 1.626 2.643 2.188 2.575.262-.032.605-1.447-.457-4.868" fill="#FFF" fill-rule="nonzero"/></g>
      </symbol>
      <symbol id="media-icon-weibo">
        <g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><path d="M22.029 13.592c1.607.483 3.396 1.649 3.396 3.707 0 3.41-5.064 7.701-12.68 7.701C6.937 25 1 22.269 1 17.777c0-2.348 1.532-5.063 4.17-7.624 3.529-3.42 7.64-4.978 9.187-3.476.683.663.748 1.807.31 3.175-.226.687.668.308.668.308 2.85-1.157 5.34-1.227 6.247.034.484.67.437 1.612-.01 2.703-.207.502.061.581.458.695h-.001zm-9.267 9.799c4.634-.447 8.146-3.2 7.848-6.15-.301-2.952-4.304-4.985-8.937-4.54-4.632.445-8.148 3.197-7.848 6.147.302 2.955 4.304 4.986 8.937 4.543zm-5.196-6.023c.96-1.884 3.452-2.95 5.658-2.394 2.282.571 3.445 2.659 2.515 4.69-.947 2.076-3.668 3.182-5.975 2.46-2.23-.698-3.172-2.831-2.198-4.756zm1.84 3.194c.723.317 1.687.016 2.133-.68.44-.7.208-1.501-.518-1.8-.716-.293-1.642.01-2.087.682-.45.676-.24 1.484.473 1.798zm2.808-2.232c.278.114.637-.015.799-.285.156-.272.056-.575-.227-.677-.274-.103-.618.023-.78.287-.156.264-.068.565.208.675zm12.803-7.056a.913.913 0 01-.687.605.94.94 0 01-.882-.28.875.875 0 01-.185-.882 1.665 1.665 0 00-.366-1.675 1.81 1.81 0 00-1.681-.525.922.922 0 01-1.094-.686.894.894 0 01.708-1.061 3.677 3.677 0 013.435 1.079c.863.93 1.15 2.235.752 3.425zm-4.983-8.116c2.59-.535 5.278.31 7.053 2.216 1.777 1.91 2.368 4.593 1.553 7.04-.087.263-.278.48-.531.606a1.096 1.096 0 01-.818.06 1.036 1.036 0 01-.69-1.31 5 5 0 00-1.102-5c-1.261-1.356-3.173-1.957-5.015-1.575-.564.087-1.1-.27-1.22-.811-.12-.542.218-1.08.77-1.226z" fill="#FFF" fill-rule="nonzero"/></g>
      </symbol> -->
      <symbol id="select-arrow">
        <path d="M6.5 7L0 0h13z" fill="#000" fill-rule="nonzero"/>
      </symbol>
    </svg>

    <div class="contact-content">
      <div class="contact__address">
        <div class="contact__title">公司地址</div>
        <div class="contact__address-list">

          <div v-for="item of company_address" class="contact__address-item">
            <div class="contact__address-label">{{ item.location }}</div>
            <div class="contact__address-text" style="line-height: 1.65">{{ item.address }}</div>
          </div>
          
          <!-- <div class="contact__address-item">
            <div class="contact__address-label">广州</div>
            <div class="contact__address-text" style="line-height: 1.65">广州珠海区新港东路2519号工美港22号306</div>
          </div>
          <div class="contact__address-item">
            <div class="contact__address-label">上海</div>
            <div class="contact__address-text" style="line-height: 1.65">上海市黄浦区淮海中路627号WeWork 6-159</div>
          </div>
          <div class="contact__address-item">
            <div class="contact__address-label">北京</div>
            <div class="contact__address-text" style="line-height: 1.65">北京市朝阳区望京东园四区中航资本大厦5-108</div>
          </div> -->
          
        </div>
      </div>

      <div class="contact__address contact__address--tel">
        <div class="contact__title">联系方式</div>
        <div class="contact__address-list">
          <div class="contact__address-item">
            <div class="contact__address-label">电话</div>
            <div class="contact__address-text" v-html="telephone"></div>
          </div>
          <div class="contact__address-item">
            <div class="contact__address-label">邮箱</div>
            <div class="contact__address-text">
              <span v-for="item of mail" class="contact__address-text-row">{{ item }}</span>
              
            </div>
          </div>
          <div class="contact__address-item contact__address-item--wechat contact__address-item--social-media-list">
            <a href="#" data-media-name="wechat" class="contact__social-media-icon contact__social-media-icon--wechat" @mouseenter="hoverMedia" @mouseleave="leaveMedia">微信
              <svg viewBox="0 0 30 30">
                <use xlink:href="#media-icon-wechat"></use>
              </svg>
            </a>
            <!-- <a href="#" data-media-name="qq" class="contact__social-media-icon contact__social-media-icon--qq" @mouseenter="hoverMedia" @mouseleave="leaveMedia">QQ
              <svg viewBox="0 0 30 30">
                <use xlink:href="#media-icon-qq"></use>
              </svg>
            </a>
            <a href="#" data-media-name="weibo" class="contact__social-media-icon contact__social-media-icon--weibo" @mouseenter="hoverMedia" @mouseleave="leaveMedia">新浪微博
              <svg viewBox="0 0 30 30">
                <use xlink:href="#media-icon-weibo"></use>
              </svg>
            </a> -->
          </div>

          <div class="qr-wrap">
            <img src="../assets/qr.png" alt="qr-code-image" />
          </div>
          
        </div>
      </div>

      <div class="contact__communication">
        <a class="contact__recruitment" href="#" @click.prevent="showRecruitment">
          <span class="contact__recruitment-icon"></span>
          <span class="contact__recruitment-text">加入我们</span>
          <span class="contact__recruitment-illus">
            <span v-for="index in 4" :key="index">
              <svg viewBox="0 0 41 87">
                <use class="contact__recruitment-illus-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </span>
          </span>
        </a>
        <a class="contact__inquiry" href="#" @click.prevent="showContactForm">
          <span class="contact__recruitment-icon"></span>
          <span class="contact__inquiry-text">顾问咨询</span>
          <span class="contact__inquiry-illus">
            <span v-for="index in 4" :key="index">
              <svg viewBox="0 0 41 87">
                <use class="contact__inquiry-illus-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </span>
          </span>
        </a>
        <div class="contact__inquiry-help">填写您的信息，请专业顾问来电为您介绍。</div>
      </div>

      
    </div>

    <footer class="copyright">Copyright (C) TrendiMedia Inc. all rights reserved. <a style="color:#fff" href="http://www.beian.mitt.gov.cn">粤ICP备16099359号-3</a></footer>

    
    <ContactForm ref="contactForm" v-if="isContactFormLoaded" />

    <Recruitment ref="recruitment" v-if="isRecruitmentLoaded" />

    <div class="notification">
      <span class="notification__deco"></span>
      <span class="notification__text">发送成功</span>
    </div>

    <a href="#" class="recruitment__close" @click.prevent="closeRecruitment">
      <span></span>
    </a>
    
  </div>
</template>

<script>
export default {
  components: {
    ContactForm: () => import('./ContactForm.vue'),
    Recruitment: () => import('./Recruitment.vue')
  },
  data() {
    return {

      company_address: [],
      telephone: '020 - 87392417<br><br>021 - 63338097',
      mail: [],

      isContactFormLoaded: 0,
      isRecruitmentLoaded: 0
    }
  },
  methods: {
    showRecruitment() {
      if (!this.isRecruitmentLoaded) {
        this.isRecruitmentLoaded = 1
      } else {
        this.$refs.recruitment && this.$refs.recruitment.recruitmentEnterAnimation.play();
      }
    },
    showContactForm() {
      if (!this.isContactFormLoaded) {
        this.isContactFormLoaded = 1
      } else {
        this.$refs.contactForm && this.$refs.contactForm.contactFormEnterAnimation.play();
      }
    },
    
    hoverMedia(e) {
      const target = $('#media-icon-' + e.target.dataset.mediaName).find('path').eq(1);
      TweenMax.to(target, .5, {
        fill: 'red'
      })

      
    },
    leaveMedia(e) {
      const target = $('#media-icon-' + e.target.dataset.mediaName).find('path').eq(1);
      TweenMax.to(target, .5, {
        fill: '#fff'
      })
    },

    closeRecruitment() {
      this.$refs.recruitment.recruitmentEnterAnimation && this.$refs.recruitment.recruitmentEnterAnimation.reverse();
    }
  },
  
  created() {
    const propHost = this.$root.$children[0];

    this.company_address = propHost.company_address.split('\n');
    this.company_address = this.company_address.map(val => {
      const o = val.split('*');
      return {
        location: o[0],
        address: o[1]
      }
    });
    
    this.telephone = propHost.telephone.split('\n').join('<br><br>');
    this.mail = propHost.mail.split('\n');

  },
  mounted() {
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome) {
      $(this.$el.querySelector('.contact__recruitment')).addClass('contact__recruitment--clip');
      $(this.$el.querySelector('.contact__inquiry')).addClass('contact__inquiry--clip');
    }
  }
}
</script>

<style lang="postcss">
.contact {
  position: absolute;
  width: 100%;
  height: 100%;

  &__address {
    width: 25.29%;
    height: 100%;

    padding: calc(30 * 100vw / 1920) calc(36 * 100vw / 1920);

    * {
      line-height: 100%;
    }
    
    &-item {
      width: calc(250 * 100vw / 1920);
      font-size: calc(16 * 100vw / 1920);
      font-weight: bold;

      &:not(:first-of-type) {
        margin-top: calc(48 * 100vw / 1920);
      }

      &--social-media-list {
        display: flex;
        
        padding: 2.5px;
        

        ^^^&__social-media-icon {
          color: transparent;
          width: calc(30 * 100vw / 1920);
          height: calc(30 * 100vw / 1920);

        
          &:not(:first-of-type) {
            margin-left: 17.76%;
          }

          position: relative;

          svg {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
          }

        }
      }

    
    }
    &-label {
      color: var(--primary-red);
      margin-bottom: calc(16 * 100vw / 1920);
    }
    &-text {
      color: #fff;
      &-row {
        display: block;
        &:not(:first-of-type) {
          margin-top: calc(16 * 100vw / 1920);
        }
      }
    }

    &--tel {
      margin-left: 4.76%;
    }

  }
  &__title {
    font-size: calc(36 * 100vw / 1920);
    font-weight: bold;
    margin-bottom: 21.17%;
    color: #fff;
  }

  &__communication {
    width: calc(420 * 100vw / 1920);
    margin-left: auto;
    display: flex;
    flex-direction: column;
    padding-top: 2.5%;
    padding-bottom: 2%;
  }

  &__inquiry {
    margin-top: auto;

    &-help {
      font-size: calc(16 * 100vw / 1920);
      color: #fff;
      margin-top: calc(36 * 100vw / 1920);
    }
  }
  &__inquiry,
  &__recruitment {
    &--clip {
      clip-path: url(#button-clip-3);
    }
    
    width: 100%;
    height: calc(100 * 100vw / 1920);
    

    /* border: 4px solid #fff; */

    background: rgba(123, 123, 123, .3);
    border-radius: 2px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    /* padding: 6%; */

    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;

      width: calc(840 * 100vw / 1920);
      height: calc(420 * 100vw / 1920);
      
      border-radius: 50%;
      right: 150%;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      transition: 2000ms 0ms all cubic-bezier(0.19, 1, 0.22, 1);

      background:rgba(123,123,123,1);
      opacity:0.3;
    }
    &:hover::before {
      transform: translateY(-50%) translateX(80%);
    }
    


    &-text {
      font-size: calc(24 * 100vw / 1920);
      font-weight: bold;
      color: #fff;
      margin-right: auto;
      
    }

    &:hover &-illus {
      transform: translateX(25%);
    }

    &-illus {
      transition: 2000ms 0ms transform cubic-bezier(0.19, 1, 0.22, 1);
      width: calc(29 * 100vw / 1920);
      height: calc(12 * 100vw / 1920);

      display: flex;
      justify-content: space-between;

      margin-right: 30px;

      & > span {
        width: 20%;
        height: 100%;
        display: block;

        position: relative;

        & svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      
    }

    
    


  }
  
}
.contact-content {
  position: absolute;
  width: 74.66%;
  top: 27.77%;
  left: 9.27%;
  right: 16%;

  display: flex;
  
}

.copyright {
  position: absolute;
  bottom: 6.1%;
  left: 11.33%;
  font-size: calc(16 * 100vw / 1920);
  color: #fff;
}

use.contact__inquiry-illus-unit,
use.contact__recruitment-illus-unit {
  fill: var(--primary-red);
}

.notification {
  visibility: hidden;
  width: calc(292 * 100vw / 1920);
  height: calc(78 * 100vw / 1920);
  background: #f1f1f1;

  display: flex;
  align-items: center;

  padding: calc(30 * 100vw / 1920);

  position: absolute;
  top: calc(30 * 100vw / 1920);
  left: calc(50% - calc(60 * 100vw /1920));
  transform: translate(-50%);

  &__deco {
    width: calc(36 * 100vw / 1920);   
    height: calc(36 * 100vw / 1920);
    margin-right: calc(16 * 100vw / 1920);

    background: url('../assets/check.png') no-repeat center;
    background-size: contain;

  
  }
  &__text {
    font-size: calc(24 * 100vw / 1920);
    color: var(--primary-black);
    font-weight: bold;
  }
}

.contact__address-item--wechat:hover+.qr-wrap {
  opacity: 1;
}

.qr-wrap {
  transition: .35s opacity;
  opacity: 0;
  position: relative;
  top: 15px;
  width: 100px;
  
  font-size: 0;
  img {
    width: 100%;
  }
}


.contact__recruitment-icon {
  margin-left: 15px;
  width: calc(32 * 100vw / 1920);
  height: calc(36 * 100vw / 1920);
  background: url('../assets/icon-1.svg') no-repeat center;
  background-size: contain;
  margin-right: calc(22 * 100vw / 1920);

}
.contact__inquiry-icon {
  margin-left: 15px;
  width: calc(37 * 100vw / 1920);
  height: calc(36 * 100vw / 1920);
  background: url('../assets/icon-2.svg') no-repeat center;
  background-size: contain;
  margin-right: calc(21 * 100vw / 1920);

}
</style>