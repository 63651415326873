import { 
  TweenMax,
  AttrPlugin,
  CSSPlugin,
  TimelineLite
} from "gsap/all";

const activated = [
  TweenMax,
  AttrPlugin,
  CSSPlugin
];

import state from '../state.js';

// function animateMenuRect() {
  
//   const tl = new TimelineLite;

//   tl
//     .to('.dock__menu', .3, {
//       x: -5,
//       autoAlpha: 0,
//       ease: Power1.easeIn
//     });

//   return tl
// }

function animateMenuIcon() {

  const tl = new TimelineLite
  
  function diffuseLine() {
    const tl = new  TimelineLite;
    tl
      .to('#l1', .225, {
        autoAlpha: 0,
        ease: Power3.easeOut,
        yPercent: -200
      })
      .to('#l4', .225, {
        autoAlpha: 0,
        ease: Power3.easeOut,
        xPercent: 200,
        
      }, '-=.225')
      .to('#l5', .225, {
        autoAlpha: 0,
        ease: Power3.easeOut,
        xPercent: -200
      }, '-=.225')
      .to('#l8', .225, {
        autoAlpha: 0,
        ease: Power3.easeOut,
        yPercent: 200
      }, '-=.225')
    tl.timeScale(.5)
    return tl
  }

  function aggregateLine() {
    const tl = new TimelineLite;
    tl
      .to('#l2', 1, {
        yPercent: 95
      })
      .to('#l3', 1, {
        xPercent: -95,
      }, '-=1')
      .to('#l6', 1, {
        xPercent: 95
      }, '-=1')
      .to('#l7', 1, {
        yPercent: -95
      }, '-=1')

    tl.timeScale(1.25);
    return tl
  }

  function rotateRect() {
    const tl = new TimelineLite;
    tl
      .to('.dock__menu', .5, {
        rotation: -45,
        ease: Power3.easeInOut
      })
    tl.timeScale(1)
    return tl
  }

  
  tl
    .add(diffuseLine())
    .add(aggregateLine(), '-=.5')
    .add(rotateRect(), '-=.5')

  return tl;
}

function animateMenu() {

  const tl = new TimelineLite;

  tl
    .fromTo('.menu-brush', 0, {
      zIndex: -1
    }, {
      zIndex: 45
    })
    .to('.menu-brush__bg', 1, {
      scaleX: 1,
      ease: Power2.easeInOut
    })
    .to('.menu', 0, {
      zIndex: 50
    })
    .staggerFromTo('.menu__column', .3, {
      xPercent: -15
    }, {
      autoAlpha: 1,
      xPercent: 0
    }, function(index) {
      return 0.125 * index;
    })

  return tl
}

function getMenuAnimation() {

  const tl = new TimelineLite({
    paused: true
  });

  tl.eventCallback('onComplete', function() {

    state.lock.menu = 0;
    state.lock.menuItem = 0;

    const topView = state.getTopView();

    const tl = new TimelineLite();

    if (topView == 5 || topView == 'caseDetail') {
      tl
        .to($('.dock__menu-rect-line'), .25, {
          backgroundColor: '#283032'
        })
    }

    if ($('.bullet-list').css('visibility') != 'hidden') {
      // hide bullet list
      tl
        .to('.bullet-list', .25, {
          autoAlpha: 0
        })
        .to('.bullet-list', 0, {
          zIndex: -100
        })
    }

    if ($('.dock__logo-text').css('visibility') != 'hidden') {
      // hide logo text
      tl
        .to('.dock__logo-text', .25, {
          autoAlpha: 0
        })
        .to('.dock__logo-text', 0, {
          zIndex: -100
        })
    }

    if ($('.case-detail-bullet-list').css('visibility') != 'hidden') {
      // hide case detail bullet
      tl
        .to('.case-detail-bullet-list', .25, {
          autoAlpha: 0
        })
        .to('.case-detail-bullet-list', 0, {
          zIndex: -100
        })
        
    }

    if ($('.recruitment-bullet-list').css('visibility') != 'hidden') {
      // hide recruitment bullet
      tl
        .to('.recruitment-bullet-list', .25, {
          autoAlpha: 0
        })
        .to('.recruitment-bullet-list', 0, {
          zIndex: -100
        }) 
    }

    // show close button
    // tl
    //   .to('.dock__menu-close', 0, { zIndex: '' })
    //   .to('.dock__menu-close', .25, { autoAlpha: 1 })

  });

  tl.eventCallback('onReverseComplete', function() {

    state.lock.menu = 0;
    state.lock.menuItem = 0;

    if (state.slider.lock) return;

    const topView = state.getTopView();

    const tl = new TimelineLite;

    if (topView == 0) {
      // show logo text
      tl
        .to('.dock__logo-text', 0, {
          zIndex: ''
        })
        .to('.dock__logo-text', .25, {
          autoAlpha: 1
        })
        .to('.dock', .5, {
          backgroundColor: '#f5f5f5'
        })
    }

    if (
      topView == 1 ||
      topView == 2 ||
      topView == 3 ||
      topView == 4 ||
      topView == 5
    ) {

      // show bullet list
      tl
        .to('.bullet-list', 0, {
          zIndex: ''
        })
        .to('.bullet-list', .25, {
          autoAlpha: 1
        })
        .to('.dock', .5, {
          backgroundColor: 'transparent'
        })
        
    }

    if (topView == 5 || topView == 'caseDetail') {
      tl.to($('.dock__menu-rect-line'), .25, {
        backgroundColor: '#fff'
      })
    }

    if (topView == 'caseDetail') {
      // show case detail bullet
      tl
        .to('.case-detail-bullet-list', 0, {
          zIndex: ''
        })
        .to('.case-detail-bullet-list', .25, {
          autoAlpha: 1
        })
        .to('.dock', .5, {
          backgroundColor: 'transparent'
        })
        
    }

    if (topView == 'recruitment') {
      // show recruitment bullet
      tl
        .to('.recruitment-bullet-list', 0, {
          zIndex: ''
        })
        .to('.recruitment-bullet-list', .25, {
          autoAlpha: 1
        })
    }
  
  });

  tl
    // .add(animateMenuRect())
    .add(animateMenuIcon())
    .add(animateMenu(), '-=.5')

  tl.timeScale(1.5);

  return tl;
  
}

export default getMenuAnimation;