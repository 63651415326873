function animateAssets() {
  const tl = new TimelineLite;

  tl
    .to($('.logo-triple__left, .logo-triple__middle, .logo-triple__right, .logo-triple__left-middle, .logo-triple__right-middle'), .5, {
      fill: '#fff'
    })
    .to('.dock__menu-rect-line', .5, {
      backgroundColor: '#fff'
    }, '-=.5')
    
  return tl
}

function animateAssetsBackwards() {
  const tl = new TimelineLite;

  tl
    .to($('.logo-triple__left, .logo-triple__middle, .logo-triple__right, .logo-triple__left-middle, .logo-triple__right-middle'), .5, {
      fill: '#E72420'
    })
    .to('.dock__menu-rect-line', .5, {
      backgroundColor: '#283032'
    }, '-=.5')
    
  return tl
}

export { animateAssets, animateAssetsBackwards }