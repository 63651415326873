import {
  TweenMax,
  CSSPlugin,
  AttrPlugin
}  from "gsap/all";

const plugins = [
  TweenMax,
  CSSPlugin,
  AttrPlugin
];

import state from '../state.js';

function hoist() {

  const tl = new TimelineLite;

  tl
    .fromTo('.about', 0, {
      zIndex: -1,
      display: 'none',
    }, {
      zIndex: 45,
      display: 'block'
    })

  return tl;
}

function animateBrush() {

  const tl = new TimelineLite;

  tl
    .fromTo('.about__brush-order-1', 1, {scaleX: 0}, {
      scaleX: 1,
      ease: Power3.easeInOut
    })
    .fromTo('.about__brush-order-2', 1, {scaleX: 0}, {
      scaleX: .5055,
      ease: Power3.easeInOut
    })
  
  return tl
}

function animateContent() {

  const tl = new TimelineLite;

  tl
    .to('.about__go-back-wrap', .5, {
      autoAlpha: 1
    })
    .to('.about-content', .5, {
      autoAlpha: 1
    });

  return tl;
}

const getAboutEnterAnimation = function() {

  const tl = new TimelineLite({
    paused: true,
    onComplete: function() {

      const tl = new TimelineLite;

      if ($('.bullet-list').css('visibility') != 'hidden') {
        // hide bullet-list
        tl
          .to('.bullet-list', .25, {
            autoAlpha: 0
          })
          .to('.bullet-list', 0, {
            zIndex: -100
          })
      }

      // show logo text
      tl
        .to('.dock__logo-text', 0, {
          zIndex: ''
        })
        .to('.dock__logo-text', .25, {
          autoAlpha: 1
        })
      

    },
    onReverseComplete: function() {
      const topView = state.getTopView();
      const tl = new TimelineLite;

      if (
        topView == 1 ||
        topView == 2 ||
        topView == 3 ||
        topView == 4 ||
        topView == 5
      ) {
        // hide logo text
        tl
          .to('.dock__logo-text', .25, {
            autoAlpha: 0
          })
          .to('.dock__logo-text', 0, {
            zIndex: -100
          })

        // show bullet list
        tl
          .to('.bullet-list', 0, {
            zIndex: ''
          })
          .to('.bullet-list', .25, {
            autoAlpha: 1
          })
          
      }
    }
  });

  tl
    .add(hoist())
    .add(animateBrush())
    .add(animateContent())

  return tl;

}

export { getAboutEnterAnimation }