<template>
  <nav class="dock">

    <div class="dock__logo logo-triple" @mouseenter="showFullLogo" @mouseleave="hideFullLogo" @click="visitHome">

      <div class="logo-triple__piece logo-triple__piece--left-middle">
        <svg viewBox="0 0 41 87">
          <use class="logo-triple__left-middle" xlink:href="#logo-frag-2"></use>
        </svg>
      </div>

      <div class="logo-triple__piece logo-triple__piece--right-middle">
        <svg viewBox="0 0 41 87">
          <use class="logo-triple__right-middle" xlink:href="#logo-frag-2"></use>
        </svg>
      </div>

      <div class="logo-triple__piece logo-triple__piece--left">
        <svg viewBox="0 0 37 87">
          <use class="logo-triple__left" xlink:href="#logo-frag-3"></use>
        </svg>
      </div>
      <div class="logo-triple__piece logo-triple__piece--middle">
        <svg viewBox="0 0 42 87">
          <use class="logo-triple__middle" xlink:href="#logo-frag-1"></use>
        </svg>
      </div>
      <div class="logo-triple__piece logo-triple__piece--right">
        <svg viewBox="0 0 37 87">
          <use class="logo-triple__right" xlink:href="#logo-frag-3"></use>
        </svg>
      </div>
    </div>

    <div class="dock__logo-text">
      <svg viewBox="0 0 12 98">
        <use xlink:href="#logo-text"></use>
      </svg>
    </div>

    <a class="dock__menu" href="#" @click.prevent="toggleMenu" @mouseenter="menuHovered">
      <div class="dock__menu-rect dock__menu-rect--1">
        <div class="dock__menu-rect-line dock__menu-rect-line--1" id="l1"></div>
        <div class="dock__menu-rect-line dock__menu-rect-line--2" id="l2"></div>
      </div>
      <div class="dock__menu-rect dock__menu-rect--2">
        <div class="dock__menu-rect-line dock__menu-rect-line--1" id="l3"></div>
        <div class="dock__menu-rect-line dock__menu-rect-line--2" id="l4"></div>
      </div>
      <div class="dock__menu-rect dock__menu-rect--3">
        <div class="dock__menu-rect-line dock__menu-rect-line--1" id="l5"></div>
        <div class="dock__menu-rect-line dock__menu-rect-line--2" id="l6"></div>
      </div>
      <div class="dock__menu-rect dock__menu-rect--4">
        <div class="dock__menu-rect-line dock__menu-rect-line--1" id="l7"></div>
        <div class="dock__menu-rect-line dock__menu-rect-line--2" id="l8"></div>
      </div>
    </a>

    <footer class="bullet-list">
      <div v-for="index in 7" :key="index" class="bullet-list__item" @click.prevent="bulletNav(index)"></div>
      <div class="bullet-list__current bullet-list__current--1">
        <span></span>
        <span></span>
      </div>
    </footer>

    <footer class="case-detail-bullet-list">
      <div v-for="index in 4" :key="index" class="case-detail-bullet-list__item" @click="navCaseDetail(index)">
        <div class="case-detail-bullet-list__outer"></div>
        <div class="case-detail-bullet-list__inner"></div>
      </div>
    </footer>

    <footer class="recruitment-bullet-list">
      <div v-for="(val, index) in recruitmentJobs" :key="index" class="recruitment-bullet-list__item" :class="{'recruitment-bullet-list__item--active': index == 0}">
        <div class="recruitment-bullet-list__outer"></div>
        <div class="recruitment-bullet-list__inner"></div>
      </div>
    </footer>

  </nav>  
</template>

<script>

import { 
    TweenMax,
    AttrPlugin,
    CSSPlugin
} from "gsap/all";

const activated = [
    TweenMax,
    AttrPlugin,
    CSSPlugin
];

import state from '../state.js';
export default {

  props: ['recruitmentJobs'],

  data() {
    return {
      logoHoverAnimation: null
    }
  },

  methods: {

    menuHovered() {
      
      const tl = new TimelineLite;
      tl
        .to($('.dock__menu-rect'), .25, {
          rotation: 90,
          ease: Power2.easeOut
        })
        .to($('#l1, #l4, #l5, #l8'), .25, {
          backgroundColor: '#E72420'
        }, '-=.25')
        .to($('.dock__menu-rect'), .25, {
          rotation: 0,
          ease: Power2.easeOut
        })
        .to($('#l1, #l4, #l5, #l8'), .25, {
          backgroundColor: '#283032'
        }, '-=.25')
    },

    bulletNav(index) {
      let indexIn;
      console.log('current', state.slider.current)
      console.log('index clicked', index)
      if (index <= state.slider.current ) {
        indexIn = index - 1;
      } else {
        indexIn = index - 2;
      }
      state.slider.lock = true;
      state.slider.animateSlide(+indexIn, state.slider.current)
        .then(function() {
          state.slider.current = +indexIn;
          state.slider.lock = false;
        })
    },

    visitHome() {
      window.location.reload();
    },

    toggleMenu(e) {
      
      if (!this.$root.$children[0].menuAnimation) {
        this.$emit('showMenu');
      } else {
        if (this.$root.$children[0].menuAnimation.progress() === 0) {
          this.$emit('showMenu');
        }
        if (this.$root.$children[0].menuAnimation.progress() == 1) {
          this.$emit('closeMenu');
        }
      }
    },

    showFullLogo() {
      this.logoHoverAnimation && this.logoHoverAnimation.play();
    },
    hideFullLogo() {
      this.logoHoverAnimation && this.logoHoverAnimation.reverse();
    },

    navCaseDetail(index) {
      
      this.$root.$children[0].currentCase = 'Case' + String.fromCharCode(index + 64);
    }
  },

  mounted() {

    function getLogoHoverAnimation() {
      const tl = new TimelineLite({paused: true});
      tl
        .to($('.logo-triple__piece--left'), .14, {
          xPercent: -125
        })
        .to($('.logo-triple__piece--right'), .14, {
          xPercent: 125
        }, '-=.14')
        .to($('.logo-triple__piece--left-middle'), .14, {
          xPercent: 0,
          autoAlpha: 1
        }, '-=.14')
        .to($('.logo-triple__piece--right-middle'), .14, {
          xPercent: 0,
          autoAlpha: 1
        }, '-=.14')

        tl.timeScale(.85);
      return tl;
    }

    this.logoHoverAnimation = getLogoHoverAnimation();

    // if(deviceDetector.device == 'tablet') {
    //   $('.dock__menu').css({
    //     width: '15px',
    //     height: '15px',
    //     bottom: '12.5%'
    //   })
    // }

  }
}
</script>

<style lang="postcss">
.dock {
  position: fixed;
  z-index: var(--dock-z-index);
  height: 100vh;

  width: var(--dock-width);
  
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;

  border-right: 1px solid rgba(255,255,255, .2);

  background-color: #f5f5f5;
  

  &__logo {
    /* width ratio 36 / 120 */
    /* top ratio 66 / 1080 */
    /* size ratio 36 / 23 */

    cursor: pointer;


    position: absolute;
    

    width: var(--dock-logo-wrap-width);
    /* height: calc(23 * var(--dock-logo-wrap-width) / 36); */
    height: 1.19472vw;
    top: calc( 66 * 100vh / 1080);

    left: 50%;
    transform: translateX(-50%);

  }

  &__logo-text {
    align-self: center;
    width: 12%;

    
  }

  &__menu {
    /* width ratio 40 / 120 */
    /* size ratio 1 / 1 */
    /* bottom ratio 61 / 1080 */

    width: var(--dock-menu-wrap-width);
    height: var(--dock-menu-wrap-width);

    position: absolute;
    display: block;

    bottom: 5.64%;

    left: 50%;
    transform: translateX(-50%);
  }

  &__menu-rect {
    position: absolute;
    
    /* width: var(--dock-menu-rect-size);
    height: var(--dock-menu-rect-size); */
    width: 42.43333%;
    height: 43.9333%;
    

    &--1 {
      top: 0;
      left: 0;

      ^&-line {
        position: absolute;
        width: 100%;
        height: 31.03%;
        left: 0;
        background: var(--primary-black);  
        &--1 {
          top: 0;
        }
        &--2 {
          bottom: 0;
        }
      }
    }

    &--2 {
      right: 0;
      top: 0;

      ^&-line {
        position: absolute;
        height: 100%;
        width: 31.03%;
        top: 0;
        background: var(--primary-black);  
        &--1 {
          left: 0;
        }
        &--2 {
          right: 0;
        }
      }
    }
    &--3 {
      bottom: 0;
      left: 0;

      ^&-line {
        position: absolute;
        height: 100%;
        width: 31.03%;
        top: 0;
        background: var(--primary-black);  
        &--1 {
          left: 0;
        }
        &--2 {
          right: 0;
        }
      }
    }
    &--4 {
      right: 0;
      bottom: 0;
      ^&-line {
        position: absolute;
        width: 100%;
        height: 31.03%;
        left: 0;
        background: var(--primary-black);  
        &--1 {
          top: 0;
        }
        &--2 {
          bottom: 0;
        }
      }
    }

    
  }
}

.logo-triple {

  &__piece {
    font-size: 0;
    position: absolute;
    height: 100%;
    top: 0;
    width: 28%;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
    }

    &--left {
      left: 0;

      
    }

    &--middle {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      
    }

    &--right {
      right: 0;
      transform: scaleX(-1) translateX(-20%);

    }

    &--left-middle {
      visibility: hidden;
      left: 0;
    }

    &--right-middle {
      visibility: hidden;
      right: 0;
      transform: scaleX(-1) translateX(-20%);
    }
  }
}

.bullet-list {

  position: absolute;
  width: calc(16 * 100vw / 1920);
  height: calc(139 * 100vw / 1920);

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  align-self: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  z-index: -999;
  visibility: hidden;

  &__item {
    
    width: var(--dock-bullet-item-size);
    height: var(--dock-bullet-item-size);
    background: #e7e7e7;

    cursor: pointer;
  }

  &__current {
    position: absolute;
    width: calc(16 * 100vw / 1920);
    height: calc(40 * 100vw / 1920);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
    cursor: default;

    left: 50%;
    transform: translateX(-50%);

    span {   
      font-size: calc(16 * 100vw / 1920);
      line-height: 100%;
      font-weight: bold;
      color: var(--primary-black);
      margin: 2px 0;
    }

    &--1 {
      top: calc(21 * 1 * 100vw / 1920); 
    }
    &--2 {
      top: calc(21 * 2 * 100vw / 1920);
    }
    &--3 {
      top: calc(21 * 3 * 100vw / 1920);
    }
    &--4 {
      top: calc(21 * 4 * 100vw / 1920);
    }
    &--5 {
      top: calc(21* 5 * 100vw / 1920);
    }
  }
}

use.logo-triple__left,
use.logo-triple__middle,
use.logo-triple__right,
use.logo-triple__left-middle,
use.logo-triple__right-middle {
  fill: var(--primary-red);
}

.case-detail-bullet-list,
.recruitment-bullet-list {
  width: calc(18 * 100vw / 1920);
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;

  visibility: hidden;
  z-index: -100;

  &__item {
    cursor: pointer;
    width: calc(18 * 100vw / 1920);
    height: calc(18 * 100vw / 1920);
    margin-top: calc(18 * 100vw / 1920);
    margin-bottom: calc(18 * 100vw / 1920);

    position: relative;

    &--active ^&__outer {
      display: block;
    }

  }

  &__outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;

    display: none;
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(7 * 100vw / 1920);
    height: calc(7 * 100vw / 1920);
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

</style>