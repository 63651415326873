import { 
  TweenMax,
  AttrPlugin,
  CSSPlugin
} from "gsap/all";

const activated = [
  TweenMax,
  AttrPlugin,
  CSSPlugin
];

import CustomEase from 'gsap/CustomEase';

export default class {

  constructor(selector, callback) {
    this.dom = {};
    this.dom.wrap = document.querySelector(selector);
    this.dom.fragMiddle = this.dom.wrap.children[0];
    this.dom.fragMiddleLeft = this.dom.fragMiddle.nextElementSibling;
    this.dom.fragLeft = this.dom.fragMiddleLeft.nextElementSibling;
    this.dom.fragMiddleRight = this.dom.fragLeft.nextElementSibling;
    this.dom.fragRight = this.dom.fragMiddleRight.nextElementSibling;

    this.callback = callback

    this.init();
  }

  init() {

    const self = this;

    TweenMax.set(this.dom.wrap, {
      autoAlpha: 1
    });

    CustomEase.create('fragMiddle', 'M0,0 C0.6,0.01 0.22,0.91 1,1');
    CustomEase.create('fragMiddleLeftRightPosition', 'M0,0 C0.25,0.1 0.25,1 1,1');
    CustomEase.create('fragMiddleLeftRightOpacity', 'M0,0 C0.25,0.1 0.25,1 1,1');
    CustomEase.create('fragLeftRightPosition', 'M0,0 C0.3,0.1 0.25,1 1,1');
    CustomEase.create('fragleLeftRightOpacity', 'M0,0 C0.25,0.1 0.25,1 1,1');

    var tl = new TimelineLite();

    this.callback &&

    tl.eventCallback('onComplete', this.callback);

    tl
      .to(this.dom.fragMiddle, .5, {
        autoAlpha: 1
      })

      .to(this.dom.fragMiddle, 1.3, {
        rotation: 720,
        ease: 'fragMiddle'
      })

      .from([
        this.dom.fragMiddleLeft,
        this.dom.fragMiddleRight,
      ], .35, {
        x: 0,
        ease: 'fragMiddleLeftRightPosition',
        clearProps: 'transform'
      })
      .from([
        this.dom.fragMiddleLeft,
        this.dom.fragMiddleRight
      ], .14, {
        autoAlpha: 0,
        ease: 'fragMiddleLeftRightOpacity'
      }, '-=.35')

      .from([
        this.dom.fragLeft,
        this.dom.fragRight
      ], .7, {
        x: 0,
        ease: 'fragLeftRightPosition',
        clearProps: 'transform'
      }, '-=.35')
      .from([
        this.dom.fragLeft,
        this.dom.fragRight
      ], .33, {
        autoAlpha: 0,
        ease: 'fragleLeftRightOpacity',
        
      }, '-=.7')

      .to('.brand-logo-text', .5, {
        autoAlpha: 1,
        ease: Power2.easeOut
      })
      
  }
}