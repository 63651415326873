<template>
  <div class="case-icon">
    <div class="pattern pattern--1" v-if="iconPattern==1">
      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><circle fill="#E7E7E7" cx="18" cy="18" r="10"/><path d="M3 2h16v16H3V2zm2 2v12h12V4H5z" fill="#E72420" fill-rule="nonzero"/></g></svg>
    </div>
    <div class="pattern pattern--2" v-if="iconPattern==2">
      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><path fill="#E7E7E7" d="M2 11h17v17H2z"/><path d="M12 18V0l18 18H12zm2.057-2.057h11.314L14.057 4.629v11.314z" fill="#E72420" fill-rule="nonzero"/></g></svg>
    </div>
    <div class="pattern pattern--3" v-if="iconPattern==3">
      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><circle fill="#E7E7E7" cx="13.5" cy="15" r="10"/><path d="M26.5 3v24h-13V3h13zm-2 2h-9v20h9V5z" fill="#E72420" fill-rule="nonzero"/></g></svg>
    </div>
    <div class="pattern pattern--4" v-if="iconPattern==4">
      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h30v30H0z"/><path fill="#E7E7E7" d="M15 13.5l-12.5 14h25z"/><path d="M15 20.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19zm0-2a7.5 7.5 0 110-15 7.5 7.5 0 010 15z" fill="#E72420" fill-rule="nonzero"/></g></svg>
    </div>
  </div>
</template>

<script>
export default {
  props: ['iconPattern']
}
</script>

<style lang="postcss">
.case-icon {
  width: 100%;
  height: 100%;
}
.pattern {
  width: 100%;
  height: 100%;

  position: relative;

}
</style>