import { 
  TweenMax,
  AttrPlugin,
  CSSPlugin,
  TimelineLite
} from "gsap/all";

const activated = [
  TweenMax,
  AttrPlugin,
  CSSPlugin
];

import { animateAssets, animateAssetsBackwards } from '../animations/assets.js';

function wrapWithSpan(text) {
  const array = text.split('');
  return array.map(val => '<span>' + val + '</span>').join('');
}

function makeDotTransparent(i) {
  $('.bullet-list__item').each(function(index, el) {
    if (index == i || index == (i + 1)) {
      $(el).css('background-color', 'transparent');
    }
  })
}

function hoistBrushLayer(outSlide, indexIn) {

  const tl = new TimelineLite;

  tl
    .to(outSlide, 0, {
      autoAlpha: 1,
      zIndex: 10
    })
    .to('.section-brush--' + indexIn, 0, {
      autoAlpha: 1,
      zIndex: 15
    })

  return tl;
}

function animateBrush(indexIn) {

  const tl = new TimelineLite;

  ;({
    '0': function() {
      tl
        .fromTo($('.section-brush--0 .section-brush__order-1'), 2, {
          scaleX: 0
        }, {
          scaleX: 1,
          ease: Power3.easeInOut
        })
        
    },
    '1': function() {
      tl
        .fromTo($('.section-brush--1 .section-brush__order-1'), 1, {
          scaleX: 0
        }, {
          scaleX: 1,
          ease: Power3.easeInOut
        })
        .fromTo('.section-brush--1 .section-brush__order-2', 1, {
          scaleX: 0
        }, {
          scaleX: .5972,
          ease: Power3.easeInOut
        });
    },

    '2': function() {
      tl
        .fromTo($('.section-brush--2 .section-brush__order-1'), 1, {
          scaleX: 0
        } ,{
          scaleX: 1,
          ease: Power3.easeInOut
        })
    },

    '3': function() {
      tl
        .fromTo($('.section-brush--3 .section-brush__order-1'), 1.5, {
          scaleX: 0
        }, {
          scaleX: 1,
          ease: Power3.easeInOut
        })
    },

    '4': function() {
      tl
        .fromTo('.section-brush--4 .section-brush__order-1', 1, {scaleX: 0}, {
          scaleX: 1,
          ease: Power3.easeInOut
        })
        .fromTo('.section-brush--4 .section-brush__order-2', 1, {scaleX: 0}, {
          scaleX: .5055,
          ease: Power3.easeInOut
        })
    },

    '5': function() {
      tl
        .fromTo($('.section-brush--5 .section-brush__order-1'), 1, {
          scaleX: 0
        }, {
          scaleX: 1,
          ease: Power3.easeInOut
        })

        .to('.dynamic-background-wrap', 0, {
          display: 'block',
          zIndex: 16
        })
        .to('.dynamic-background-wrap', .5, {
          autoAlpha: 1
        })

    },

    
  })[indexIn]();

  return tl;
}

function hoistSlide(outSlide, inSlide) {

  const tl = new TimelineLite({
    onComplete: function() {
      // clean outSlide brush after each inSlide
      $('.section-brush--' + outSlide.dataset.sectionIndex + ' > div').each((index, el) => {
        el.removeAttribute('style');
      });
    }
  });

  function lowerOutSlide() {
    const tl = new TimelineLite;
    
    tl
      .to(outSlide, 0, {
        zIndex: -1,
        autoAlpha: 0
      })
      
    return tl
  }

  function hoistInSilde() {

    const tl = new TimelineLite({});

    tl
      .to(inSlide, 0, {
        zIndex: 20,
        autoAlpha: 1
      });

    // individual dock management
    const indexIn = inSlide.dataset.sectionIndex;

    if (
      indexIn == 1 || 
      indexIn == 2 || 
      indexIn == 3 ||
      indexIn == 4 ||
      indexIn == 5
    ) {

      // bullet list dot change START
      $('.bullet-list__current').html( (function() {        

        $('.bullet-list__item').css('background-color', '#e7e7e7',);

        return ({
          '1': function() {
            makeDotTransparent(1);
            return wrapWithSpan('产品');
          },
          '2': function() {
            makeDotTransparent(2);
            return wrapWithSpan('案例');
          },
          '3': function() {
            makeDotTransparent(3);
            return wrapWithSpan('伙伴');
          },
          '4': function() {
            makeDotTransparent(4);
            return wrapWithSpan('关于');
          },
          '5': function() {
            makeDotTransparent(5);
            return wrapWithSpan('联系');
          }
        })[indexIn]()
      })() );

      $('.bullet-list__current')
        .attr('class', 'bullet-list__current')
        .addClass('bullet-list__current--' + (indexIn));

      if (indexIn == 5) {
        $('.bullet-list__current > span')[0].offsetHeight;
        $('.bullet-list__current > span').css('color', '#fff');
      } else {
        $('.bullet-list__current > span')[0].offsetHeight;
        $('.bullet-list__current > span').css('color', '#283032');
      }

      tl
        .to('.dock__logo-text', .25, { autoAlpha : 0})
        .to('.dock__logo-text', 0, { zIndex: -100})
        .to('.bullet-list', 0, { zIndex: ''})
        .to('.bullet-list', .25, {autoAlpha: 1})

      // bullet list dot change END

    }

    if (indexIn == 0) {
      tl
        .to('.bullet-list', .25, {autoAlpha: 0})
        .to('.bullet-list', 0, { zIndex: '-100'})

        .to('.dock__logo-text', 0, { zIndex: ''})
        .to('.dock__logo-text', .25, { autoAlpha : 1})
    }

    return tl;
  }

  function animateInSlide() {

    const tl = new TimelineLite;
    const sectionIndex = inSlide.dataset.sectionIndex;

    if (sectionIndex == 0) {
      tl
        .fromTo('.preface', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1,
        })
    }

    if (sectionIndex == 1) {
      tl
        .fromTo('.goal-wrap', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1,
        })
        .fromTo('.platform', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1,
        }, '-=.25')
        .fromTo('.platform-data', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1,
        }, '-=.25')
        .fromTo('.intro-divider', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1,
        }, '-=.5')
        .fromTo('.intro-bg', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1
        }, '-=1')
    }

    if (sectionIndex == 2) {
      tl
        .fromTo('.case-list', .5, {
          autoAlpha: 0,
        }, {
          autoAlpha: 1,
          ease: Power3.easeIn,
        })
    }

    if (sectionIndex == 3) {
      tl
        .fromTo('.partner-list', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1
        })
    }

    if (sectionIndex == 4) {
      tl
        .fromTo('.about-content', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1
        })
        .fromTo('.about-bg', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1
        })
    }

    if (sectionIndex == 5) {
      tl
        .fromTo('.contact-content', .5, {
          autoAlpha: 0
        }, {
          autoAlpha: 1
        })

        .add(animateAssets())
    }

    return tl;
  }

  tl
    .add(lowerOutSlide())
    .add(hoistInSilde())
    .add(animateInSlide())
    
  return tl;
}

import state from '../state.js';

export default class {

  constructor({slides}) {
    this.slides = slides;
    this.current = 0;
    this.lock = false;

    this.brushes = {};

    state.slider = this;
  }
  
  shift(direction) {
    if (direction) {
      // next
      if (this.current == this.slides.length - 1) {
        // console.log('no more slide to next');
        return
      }

    } else {
      // previous
      if (this.current == 0) {
        // console.log('no more slide to prev');
        return
      }
    }

    if (!this.lock) {

      this.lock = true;

      const indexIn = direction ? this.current + 1 : this.current - 1;
      const indexOut = this.current;

      this.animateSlide(indexIn, indexOut)
        .then(() => {
          this.current = direction ? this.current + 1 : this.current - 1;
          this.lock = false;
          // console.log('current: ' + this.current);
        });
    }

  }

  animateSlide(indexIn, indexOut) {

    // reset isolated slides
    const isolatedSlides = this.slides.filter((val, index) => {
      if (index != indexIn && index != indexOut) {
        return true
      }
    });
    TweenMax.set(isolatedSlides, {
      autoAlpha: 0,
      zIndex: -1
    });

    // reset isolated brushes
    const isolatedSlideBrushes = $('.section-brush').not('.section-brush--' + indexIn)
    TweenMax.set(isolatedSlideBrushes, {
      zIndex: -1,
    });

    // specific reset
    if (indexIn == 0) {
      // section 0 has specific dock background
      TweenMax.to('.dock', 1, {
        backgroundColor: '#f5f5f5'
      })
    } 
    if (indexIn == 1 || indexIn == 2 || indexIn == 3 || indexIn == 4 || indexIn == 5) {
      TweenMax.to('.dock', 1, {
        backgroundColor: 'transparent'
      })
    }

    if (indexOut == 5) {
      const tl = new TimelineLite;

      tl
        .to('.dynamic-background-wrap', 0, {
          zIndex: 9,
        })
        .to('.dynamic-background-wrap', 1, {
          autoAlpha: 0,  
        })
        .to('.dynamic-background-wrap', 0, {
          display: 'none'
        })

        .add(animateAssetsBackwards())
    }

    const tl = new TimelineLite({
      paused: true
    });
    
    tl
      .add(hoistBrushLayer(this.slides[indexOut], indexIn))
      .add(animateBrush(indexIn))
      .add(hoistSlide(this.slides[indexOut], this.slides[indexIn]), '-=.5')
  
    .timeScale(1.25)
    
    return new Promise(function(resolve) {
      tl.eventCallback('onComplete', resolve)
      tl.play()
    });
    
  }

}