export default {
  // host: 'https://39.106.5.14',
  host: 'https://www.trendimedia.com',
  // host: 'http://localhost:8888',
  lock: {
    menu: 0,
    menuItem: 0,
    contactSubmit: 0
  },

  getTopView() {
    if ($('.case-detail').css('display') == 'block') {
      return 'caseDetail'
    }
    return this.slider.current;
  }
}