<template>
  <div id="app">
    <Dock @showMenu="showMenu" @closeMenu="closeMenu" />
    <Menu ref="menu" />
    <CaseDetail ref="caseDetail" v-if="isCaseDetailLoaded" :currentCase="currentCase" />
    <router-view ref="routerView" :caseListBgLoaded="caseListBgLoaded" :partnerImgsLoaded="partnerImgsLoaded" />
  </div>
</template>

<script>
import Dock from './components/Dock.vue';
import Menu from './components/Menu.vue';

import { 
    TweenMax,
    AttrPlugin,
    CSSPlugin
} from "gsap/all";

const activated = [
    TweenMax,
    AttrPlugin,
    CSSPlugin
];


import getMenuAnimation from './animations/menu.js';

import state from './state.js';

export default {

  props: [
    'solution_1_title',
    'solution_1_subtitle',
    'solution_1_description',
    'solution_2_title',
    'solution_2_subtitle',
    'solution_2_description',
    'platform_title',
    'platform_feature_1_title',
    'platform_feature_1_thumbnail',
    'platform_feature_2_title',
    'platform_feature_2_thumbnail',
    'platform_feature_3_title',
    'platform_feature_3_thumbnail',
    'platform_feature_4_title',
    'platform_feature_4_thumbnail',
    'product_data_1_label',
    'product_data_1_value',
    'product_data_2_label',
    'product_data_2_value',

    'about_title',
    'about_subtitle',
    'about_description_1',
    'about_description_2',

    'company_address',
    'telephone',
    'mail',


  ],

  components: {
    Dock,
    Menu,
    CaseDetail: () => import('./components/CaseDetail.vue')
  },

  data () {

    return {

      movingBox: null,
      caseListBgLoaded: 0,
      partnerImgsLoaded: 0,

      menuAnimation: null,

      isCaseDetailLoaded: 0,

      currentCase: null,
    }
  },

  methods: {

    showMenu() {

      if (state.lock.menu) return;

      TweenMax
        .to('.dock', .5, { backgroundColor: '#fff'});

      state.lock.menu = 1;
      state.lock.menuItem = 1;

      this.menuAnimation = this.menuAnimation || getMenuAnimation();
      this.menuAnimation.play();
      
    },

    closeMenu() {

      if (state.lock.menu) return;

      // reset text color
      TweenMax.to($('.menu__column-text'), .5, {
        color: this.textColor
      });
      
      // reset border color
      TweenMax.to(
        $('.menu__column')
      , .5, {
        borderColor: '#f1f1f1'
      });

      state.lock.menu = 1;
      state.lock.menuItem = 1;

      if (this.menuAnimation) {
        this.$refs.menu.movingBoxInited = 0;
        TweenMax.to('.menu__moving-box', .3, {
          autoAlpha: 0
        });
        this.menuAnimation.reverse();
      }
      
    }

  }
}
</script>

<style lang="postcss">

@import './styles/theme.css';
@import "./styles/var.css";
@import "./styles/common.postcss";
@import './styles/font.postcss';

#app {
  height: 100%;
}
</style>
