<template>
  <div class="preface">

    <div class="video-bg-wrap">
      <video class="video-bg" autoplay="autoplay" loop="loop" muted preload="true" playsinline poster="../assets/video-poster.jpg">
        <source  type="video/mp4" src="../assets/video-bg.mp4">
      </video>
    </div>

    <div class="slogan">
      <div class="slogan__part-1">
        <span>风</span>
        <span class="slogan__deco">
          <svg viewBox="0 0 37 87">
            <use class="slogan__deco-unit" xlink:href="#logo-frag-3"></use>
          </svg>
        </span>
        <span>起</span>
        <span>云</span>
        <span>集</span>
      </div>
      <div class="slogan__part-2">
        <span>尚</span>
        <span>领</span>
        <span>智</span>
        <span class="slogan__deco">
          <svg viewBox="0 0 37 87">
            <use class="slogan__deco-unit" xlink:href="#logo-frag-3"></use>
          </svg>
        </span>
        <span>汇</span>
        
      </div>
    </div>

    <header class="brand-heading">
      <h1>家庭场景<br>品效管家</h1>
      <div class="brand-heading__deco">
        <svg viewBox="0 0 37 87">
          <use class="brand-heading__deco-unit" xlink:href="#logo-frag-3"></use>
        </svg>
      </div>
    </header>

    <footer class="scroll-hint">
      <span></span>
    </footer>

  </div>
</template>

<script>
import { 
    TimelineMax,
    CSSPlugin
} from "gsap/all";

const activated = [
    TimelineMax,
    CSSPlugin
];

export default {

  methods: {

    tweenScrollHint() {

      const tlArrow = new TimelineMax({
        repeat: -1,
        repeatDelay: .5
      });

      const arrowEls = document.querySelectorAll('.scroll-hint__arrow');

      tlArrow
        .staggerTo(arrowEls, .25, {
          autoAlpha: 1,
        }, .1)
        .staggerTo(arrowEls, .15, {
          autoAlpha: 0
        }, .015);
    }
  },

  mounted() {
    this.tweenScrollHint();
  }
}
</script>

<style lang="postcss">
.preface {
  background: #fff;

  /* background: url('../assets/foo.jpg') no-repeat center / contain; */

  position: absolute;
  width: 100%;
  height: 100%;
}
.slogan {
  position: absolute;
  
  top: 6.11%;
  left: 3.77%;

  display: flex;

  &__part-1,
  &__part-2 {
    height: 100%;
    width: calc(34 * 100vw / 1920);
    
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: calc(31 * 100vw / 1920);
      font-weight: bold;
      color: #969A9B;
      line-height: 100%;
      
      &:not(:first-of-type) {
        margin-top: 1rem;
      }
    }
    
  }
  &__part-2 {
    margin-left: 2.1rem;
  }

  &__deco {
    width: calc(34 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
    
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);

    svg {
      width: 25%;
      
    }
    
  }

  
}

.brand-heading {
  position: absolute;
  
  left: 3.33%;
  bottom: 16.3%;

  color: #283032;
  font-size: calc(36 * 100vw / 1920);


  &__deco {
    content: '';
    position: absolute;

    width: calc(16 * 100vw / 1920);
    height: calc(39 * 100vw / 1920);

    left: 3.6%;
    bottom: 115%;
    transform-origin: bottom;
    transform: rotate(90deg) translate(calc(-.5 * 39 * 100vw / 1920), 25%);

    svg {
      height: 100%;
      
    }

  }
}

.scroll-hint {
  /* width: calc(40 * 100vw / 1920);
  height: calc(40 * 100vw / 1920); */
  width: 40px;
  height: 40px;
  
  position: absolute;
  bottom: 5.65%;
  right: 3.66%;
  &::before {
    content: '';
    position: absolute;
    width: 18px;
    height: 28px;
    border-radius: 2px 2px 9px 9px;
    border: 2px solid rgba(40,48,50,1);

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  span {
    position: absolute;
    width: 2px;
    height: 5px;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--primary-black);
    border-radius: 10px;
    animation: scroll 1.5s infinite ease-out;
    opacity: 0;
  }

  
}

@keyframes scroll {
    10% {
      opacity: 1;
    }
    70%, 100% {
      transform: translateX(-50%) translateY(75%);
      opacity: 0;
    }
  }

.video-bg-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}

.video-bg {
  position: absolute;
  right: 0;
  top: 0;
  height: 92.75%; 
}

use.slogan__deco-unit,
use.brand-heading__deco-unit {
  fill: var(--primary-red);
}

use.scroll-hint__arrow-unit {
  fill: var(-primary-black);
}
</style>