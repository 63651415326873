<template>
  <div class="case-list">
    
    <svg>
      <defs>
        <clipPath id="caselist-moving-box-clip" clipPathUnits="objectBoundingBox">
          <path d="M0,0 L1,0 C1,0.166204132 1,0.33271662 1,0.499537465 C1,0.66635831 1,0.833179155 1,1 L0,1 C0,0.833024977 0,0.666204132 0,0.499537465 C0,0.332870799 0,0.16635831 0,0 Z" />
          <!--
          M0,0 L1,0 C1,0.166204132 1,0.33271662 1,0.499537465 C1,0.66635831 1,0.833179155 1,1 L0,1 C0,0.833024977 0,0.666204132 0,0.499537465 C0,0.332870799 0,0.16635831 0,0 Z
          M0,0 L1,0 C0.958333333,0.166204132 0.9375,0.33271662 0.9375,0.499537465 C0.9375,0.66635831 0.958333333,0.833179155 1,1 L0,1 C0.0416666667,0.833333333 0.0625,0.666666667 0.0625,0.5 C0.0625,0.333333333 0.0416666667,0.166666667 0,0 Z
          -->
        </clipPath>
      </defs>
    </svg>

    <div class="case-list__moving-box">

      <div class="case-list__moving-box-background case-list__moving-box-background--1"></div>
      <div class="case-list__moving-box-background case-list__moving-box-background--2"></div>
      <div class="case-list__moving-box-background case-list__moving-box-background--3"></div>
      <div class="case-list__moving-box-background case-list__moving-box-background--4"></div>
      

      <div class="attachment">
        <div class="attachment__header">
          <div class="attachment__number">
            <div class="attachment__number-text"><span class="attachment__number-1">125</span>%</div>
            <div class="attachment__number-label"></div>
          </div>
          <div class="attachment__number">
            <div class="attachment__number-text"><span class="attachment__number-2">50</span>%</div>
            <div class="attachment__number-label"></div>
          </div>
        </div>
        <a href="#" class="attachment__link" v-calc @click.prevent="visitCaseDetail">
          <span class="attachment__link-text">了解详情</span>
          <span class="attachment__link-icon">
            <span class="attachment__link-icon-frag">
              <svg viewBox="0 0 41 87">
                <use class="attachment__link-icon-frag-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </span>
            <span class="attachment__link-icon-frag">
              <svg viewBox="0 0 41 87">
                <use class="attachment__link-icon-frag-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </span>
            <span class="attachment__link-icon-frag">
              <svg viewBox="0 0 41 87">
                <use class="attachment__link-icon-frag-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </span>
            <span class="attachment__link-icon-frag">
              <svg viewBox="0 0 41 87">
                <use class="attachment__link-icon-frag-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </span>
            
          </span>
        </a>
      </div>

    </div>

    <div class="case-list__item"
      @mouseenter="hoverCaseItem"
      @mouseleave="leaveCaseItem"
      @click.prevent="visitCaseDetailShortcut"
      data-background-postfix="1"
      data-text1-label="曝光完成率提升到"
      data-text2-label="3+%当周增幅达到"
      data-text1-num="118"
      data-text2-num="71"
      data-case-id="a"
    >
      <div class="case-list__item-content">
        <header class="case-list__item-icon">
          <CaseIcon icon-pattern="1" />
        </header>
        <!-- <header class="case-list__item-title">帮助客户A完成节假日重点市场SOV</header> -->
        <header class="case-list__item-title">通过iTV资源整合避免节假日收视波动带来的投放风险</header>
        <header class="case-list__item-description">春节期间由于消费者媒体行为波动，导致上海市场曝光和Reach%完成情况不够理想，将OTT投放升级为iTV投放后，曝光和3+%出现显著提升。
          <div class="case-list__item-description-illus">
            <svg viewBox="0 0 37 87">
              <use class="case-list__item-description-illus-unit" xlink:href="#logo-frag-3"></use>
            </svg>
          </div>
        </header>
        <footer class="case-list__item-order">
          <span class="case-list__item-order-numerator">01</span>
          <span class="case-list__item-order-slash"></span>
          <span class="case-list__item-order-denominator">04</span>
        </footer>
      </div>
    </div>

    <div class="case-list__item"
      @mouseenter="hoverCaseItem"
      @mouseleave="leaveCaseItem"
      @click.prevent="visitCaseDetailShortcut"
      data-background-postfix="2"
      data-text1-label="曝光完成率"
      data-text2-label="覆盖家庭户"
      data-text1-num="125"
      data-text2-num="54"
      data-case-id="b"
    >
      <div class="case-list__item-content">
        <header class="case-list__item-icon">
          <CaseIcon icon-pattern="2" />
        </header>
        <!-- <header class="case-list__item-title">完成客户B上海市场覆盖50%家庭的目标</header> -->
        <header class="case-list__item-title">完成广告主重点市场家庭用户与目标人群的高覆盖目标</header>
        <header class="case-list__item-description">采用OTT+IPTV的组合投放方式，达成客户在上海地区50%+覆盖要求。
          <div class="case-list__item-description-illus">
            <svg viewBox="0 0 37 87">
              <use class="case-list__item-description-illus-unit" xlink:href="#logo-frag-3"></use>
            </svg>
          </div>
        </header>
        <footer class="case-list__item-order">
          <span class="case-list__item-order-numerator">02</span>
          <span class="case-list__item-order-slash"></span>
          <span class="case-list__item-order-denominator">04</span>
        </footer>
      </div>
    </div>

    <div class="case-list__item"
      @mouseenter="hoverCaseItem"
      @mouseleave="leaveCaseItem"
      @mouseclick.prevent="visitCaseDetailShortcut"
      data-background-postfix="3"
      data-text1-label="触达率平均提升"
      data-text2-label="最高提升"
      data-text1-num="15"
      data-text2-num="26"
      data-case-id="c"
    >
      <div class="case-list__item-content">
        <header class="case-list__item-icon">
          <CaseIcon icon-pattern="3" />
        </header>
        <!-- <header class="case-list__item-title">客户C通过iTV优化了重庆市场的覆盖效果</header> -->
        <header class="case-list__item-title">依托iTV资源整合提升重点市场ROI</header>
        <header class="case-list__item-description">随着重庆有线资源程序化的对接完成，客户在该市场的ROI得到了进一步优化。
          <div class="case-list__item-description-illus">
            <svg viewBox="0 0 37 87">
              <use class="case-list__item-description-illus-unit" xlink:href="#logo-frag-3"></use>
            </svg>
          </div>
        </header>
        <footer class="case-list__item-order">
          <span class="case-list__item-order-numerator">03</span>
          <span class="case-list__item-order-slash"></span>
          <span class="case-list__item-order-denominator">04</span>
        </footer>
      </div>
    </div>

    <div class="case-list__item"
      @mouseenter="hoverCaseItem"
      @mouseleave="leaveCaseItem"
      @click.prevent="visitCaseDetailShortcut"
      data-background-postfix="4"
      data-text1-label="地域定向精准度提升"
      data-text2-label="有效频次内曝光提升"
      data-text1-num="59"
      data-text2-num="37"
      data-case-id="d"
    >
      <div class="case-list__item-content">
        <header class="case-list__item-icon">
          <CaseIcon icon-pattern="4" />
        </header>
        <!-- <header class="case-list__item-title">麒麟帮助客户D管理跨屏投放</header> -->
        <header class="case-list__item-title">智能广告系统助力广告主多屏整合营销</header>
        <header class="case-list__item-description">客户通过TrendiMedia麒麟平台进行多屏联动营销，媒介资源涵盖移动、PC、大屏端资源。
          <div class="case-list__item-description-illus">
            <svg viewBox="0 0 37 87">
              <use class="case-list__item-description-illus-unit" xlink:href="#logo-frag-3"></use>
            </svg>
          </div>
        </header>
        <footer class="case-list__item-order">
          <span class="case-list__item-order-numerator">04</span>
          <span class="case-list__item-order-slash"></span>
          <span class="case-list__item-order-denominator">04</span>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import CaseIcon from './CaseIcon.vue';

import { 
    TweenMax,
    AttrPlugin,
    CSSPlugin
} from "gsap/all";

const activated = [
    TweenMax,
    AttrPlugin,
    CSSPlugin
];

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export default {

  data() {
    return {
      movingBox: null,
      currentCase: null
    }
  },

  components: {
    CaseIcon
  },

  directives: {
    calc: {
      inserted: function(el) {
        const reducedCaseListItemHeight = el.getBoundingClientRect().top / window.innerHeight * 100 + '%';
        $(arguments[2].context.$el).find('.case-list__item').each((index, el) => {
          TweenMax.set(el, {
            height: reducedCaseListItemHeight
          })
        })
      }
    }
  },
  
  mounted() {

    this.clipPath = this.$el.querySelector('#caselist-moving-box-clip path');
    
    this.clipPathStartPath = 'M0,0 L1,0 C1,0.166204132 1,0.33271662 1,0.499537465 C1,0.66635831 1,0.833179155 1,1 L0,1 C0,0.833024977 0,0.666204132 0,0.499537465 C0,0.332870799 0,0.16635831 0,0 Z';
    this.clipPathEndPath = 'M0,0 L1,0 C0.958333333,0.166204132 0.9375,0.33271662 0.9375,0.499537465 C0.9375,0.66635831 0.958333333,0.833179155 1,1 L0,1 C0.0416666667,0.833333333 0.0625,0.666666667 0.0625,0.5 C0.0625,0.333333333 0.0416666667,0.166666667 0,0 Z';


  },
  methods: {

    visitCaseDetailShortcut(e) {
      this.hoverCaseItem(e);
    },

    visitCaseDetail(e) {
      const appVm = this.$root.$children[0];
      appVm.currentCase = this.currentCase;

      if (appVm.$refs.caseDetail) {
        // casedetail component exists
        appVm.$refs.caseDetail.caseDetailEnterAnimation.play();
      } else {
        // load casedetail component for the first time
        appVm.isCaseDetailLoaded = 1;
      }

    },

    hoverCaseItem: function(e) {

      this.currentCase = 'Case' + e.target.dataset.caseId.toUpperCase();

      const vm = this;

      this.movingBox = this.movingBox || this.$el.querySelector('.case-list__moving-box');

      if (!this.movingBox) return;

      // restore sibling column color
      const $itemRoot = $(e.target).closest('.case-list__item').siblings('.case-list__item');
      TweenMax.to($itemRoot.find('.case-list__item-title'), .5, {
        color: '#283032'
      });
      TweenMax.to(
        $itemRoot.find('.case-list__item-description'), .5, {
        color: '#7b7b7b'
      });

      // current column change color
      TweenMax.to([
        e.target.querySelector('.case-list__item-title'),
        e.target.querySelector('.case-list__item-description'),
      ], .5, {
        color: '#fff'
      });

      // moving cliper dom
      TweenMax.to(this.movingBox, 1.25, {
        x: e.target.offsetLeft + this.movingBox.offsetWidth,
        ease: Power4.easeOut
      });

      // restore clip path (possible bug workaround)
      $(this.clipPath).attr('d', this.clipPathStartPath);

      
      if (isSafari) {
        $(this.clipPath).attr('d', this.clipPathEndPath);
      } else {
        // morph clip-path
        TweenMax.killTweensOf(this.clipPath);
        TweenMax
          .to(this.clipPath, 1.5 ,{
            delay: .85,
            attr: {
              d: this.clipPathEndPath
            }
          });
      }

      if (!this.movingBoxInited) {
        TweenMax.to(this.movingBox, 0.3, {
          autoAlpha: 1,
          onComplete: function() {
            vm.movingBoxInited = 1
          }
        });
      }

      // animate num
      $('.attachment__number-label').eq(0).text(e.target.dataset.text1Label)
      $('.attachment__number-label').eq(1).text(e.target.dataset.text2Label)


      let count1 = {
        val: Math.floor(Math.random()* 101) + 100
      };
      let newCount1 = e.target.dataset.text1Num;
      TweenMax.to(count1, 1, {
        val: newCount1,
        
        onUpdate: function() {
          vm.movingBox.querySelector('.attachment__number-1')
            .textContent = count1.val >> 0;
        }
      });

      let count2 = {
        val: Math.floor(Math.random()* 101) + 100
      };
      let newCount2 = e.target.dataset.text2Num;
      TweenMax.to(count2, 1, {
        val: newCount2,
        
        onUpdate: function() {
          vm.movingBox.querySelector('.attachment__number-2')
            .textContent = count2.val >> 0;
        }
      });

      // animate incoming background
      const incomingBackgroundEl = this.movingBox.querySelector('.case-list__moving-box-background--' + e.target.dataset.backgroundPostfix);
      TweenMax.fromTo(incomingBackgroundEl, 1, {
        autoAlpha: 0,
        scale: 1.45,
        ease: Power2.easeOut
      }, {
        autoAlpha: 1,
        zIndex: 5,
        scale: 1
      });

      // remove order and icon
      TweenMax.to($(e.target).find('.case-list__item-order, .case-list__item-icon'), .35, {
        autoAlpha: 0
      });

      // restore order and icon
      const $closestItem = $(e.target).closest('.case-list__item');
      $closestItem.length > 0 &&
      TweenMax.to($closestItem.siblings('.case-list__item').find('.case-list__item-order, .case-list__item-icon'), .5, {
        autoAlpha: 1
      });
      
    },

    leaveCaseItem(e) {
      
      if (!this.movingBox) return;

      const outgoingBackgroundEl = document.querySelector('.case-list__moving-box-background--' + e.target.dataset.backgroundPostfix);
      
      if (e.toElement && $(e.toElement).closest('.case-list__item').length > 0) {
        
        TweenMax.killTweensOf(this.movingBox);

        const backgroundEls = $(this.movingBox).find('.case-list__moving-box-background').toArray();

        const isolatedBackgroundEl = backgroundEls.filter(function(el) {
          if (
            !el.classList.contains('case-list__moving-box-background--' + e.target.dataset.backgroundPostfix) &&
            !el.classList.contains('case-list__moving-box-background--' + e.toElement.dataset.backgroundPostfix)
          ) {
            return true
          }
        });

        TweenMax.killTweensOf(isolatedBackgroundEl);
        TweenMax.to(isolatedBackgroundEl, .25, {
          autoAlpha: 0,
          zIndex: 1
        });

        TweenMax.killTweensOf(outgoingBackgroundEl);
        TweenMax.to(outgoingBackgroundEl, .5, {  
          autoAlpha: 0,
          zIndex: 2
        });

      }

    }
  }
}
</script>

<style lang="postcss">
.case-list {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 3.33%;
  right: 3.33%;

  display: flex;
  flex-wrap: nowrap;

  & > svg {
    position: absolute;
    width: 0;
    height: 0;
  }

  &__moving-box {

    clip-path: url(#caselist-moving-box-clip);

    opacity: 0;

    position: absolute;
    width: calc(100% / 4);
    height: 100%;
    top: 0;
    left: calc(100% / 4 * -1);
    
    overflow: hidden;

    &-background {
      position: absolute;
      width: 100%;
      height: 71.71%;
      top: 0;
      left: 0;

      z-index: 1;

      &--1 {
        background: url('../assets/case-bg-1.jpg') no-repeat center / cover;
      }

      &--2 {
        background: url('../assets/case-bg-2.jpg') no-repeat center / cover;
        z-index: -1;
      }

      &--3 {
        background: url('../assets/case-bg-3.jpg') no-repeat center / cover;
        z-index: -1;
      }

      &--4 {
        background: url('../assets/case-bg-4.jpg') no-repeat center / cover;
        z-index: -1;
      }
    }
    
  }

  &__item {
    width: calc(100% / 4);
    height: 100%;
    position: relative;

    &-content {
      position: absolute;
      left: 14.285%;
      top: 26.38%;
      width: 71.42%;
      bottom: 33.24%;

    }

    &-icon {
      width: calc(60 * 100vw / 1920);
      height: calc(60 * 100vw / 1920);
    }

    &-title {
      font-weight: bold;
      color: var(--primary-black);
      font-size: calc(30 * 100vw / 1920);
      margin: 12% auto 14.66%;
      line-height: calc(42 * 100vw / 1920);

      height: 37.5%;
      
    }

    &-description {
      color: #7b7b7b;
      font-size: calc(14 * 100vw / 1920);
      
      line-height: calc(28 * 100vw / 1920);

      position: relative;

      

      &-illus {
        width: calc(6 * 100vw / 1920);
        height: calc(13 * 100vw / 1920);
        position: absolute;
        left: 0;
        bottom: 105%;

        transform: translateX(100%) rotate(90deg);

        svg {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50% ,-50%);
        }
      }
    }

    &-order {
      position: absolute;
      /* bottom: 0; */
      /* bottom: -17.5%; */
      bottom: -28.5%;
      width: 100%;
      left: 0;
      font-family: 'Oxanium Regular';
      &-numerator {
        
        font-size: calc(36 * 100vw / 1920);
        color: #000;
      }
      &-denominator {
        font-weight: bold;
        font-size: calc(18 * 100vw / 1920);
        color: #7b7b7b;
      }

      &-slash {
        display: inline-block;
        width: calc(8 * 100vw / 1920);
        height: calc(8 * 100vw / 1920);
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          border-radius: 4px;
          width: 100%;
          height: 2px;
          background: #e7e7e7;
        }
      }
    }

  }

  

}
.attachment {
  background: var(--primary-red);
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 28.3%;
  bottom: 0;

  &__header{
    display: flex;
    align-items: center;
    /* width: 100%; */
    /* margin: 16.5% 0; */
    width: 90%;
    margin: 12% auto 17.5%;
  }

  &__number {
    width: 50%;
    flex: 1 1 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    &-text {
      color: #fff;
      font-size: calc(36 * 100vw / 1920);
      font-weight: bold;
      font-family: 'Oxanium Regular';
    }
    &-label {
      
      font-size: calc(14 * 100vw / 1920);
      color: #fff;
    }
  }

  &__link {
    border: 2px solid #fff;
    border-radius: 4px;
    padding: 5% 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* width: 75%; */
    width: 62.5%;
    height: 22.5%;

    margin-left: auto;
    margin-right: auto;


    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;

      width: calc(367.5 * 100vw / 1920);
      height: calc(183.75 * 100vw / 1920);
      
      border-radius: 50%;
      right: 150%;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      transition: 2000ms 0ms all cubic-bezier(0.19, 1, 0.22, 1);

      background:rgba(255,255,255,1);
      opacity:0.15;
    }
    &:hover::before {
      transform: translateY(-50%) translateX(120%);
    }
    
    &-text {
      font-size: calc(16 * 100vw / 1920);
      color: #fff;
      margin-right: auto;


    }

    &:hover &-icon {
      transform: translateX(25%);
    }

    &-icon {
      transition: 2000ms 0ms transform cubic-bezier(0.19, 1, 0.22, 1);
      width: calc(29 * 100vw / 1920);
      height: calc(12 * 100vw / 1920);

      display: flex;
      justify-content: space-between;

      &-frag {
        width: 17.5%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
        }
      }
    }
  }
}

use.attachment__link-icon-frag-unit {
  fill: #fff;
}



use.case-list__item-description-illus-unit {
  fill: #e7e7e7;
}



</style>
