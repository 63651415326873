<template>
  <div class="menu">
    
    <svg>
      <defs>
        <clipPath id="menu-clip-path" clipPathUnits="objectBoundingBox">
          <path d="M0,0 L1,0 C1,0.166204132 1,0.33271662 1,0.499537465 C1,0.66635831 1,0.833179155 1,1 L0,1 C0,0.833024977 0,0.666204132 0,0.499537465 C0,0.332870799 0,0.16635831 0,0 Z" />
          <!--
          M0,0 L1,0 C1,0.166204132 1,0.33271662 1,0.499537465 C1,0.66635831 1,0.833179155 1,1 L0,1 C0,0.833024977 0,0.666204132 0,0.499537465 C0,0.332870799 0,0.16635831 0,0 Z
          M0,0 L1,0 C0.958333333,0.166204132 0.9375,0.33271662 0.9375,0.499537465 C0.9375,0.66635831 0.958333333,0.833179155 1,1 L0,1 C0.0416666667,0.833333333 0.0625,0.666666667 0.0625,0.5 C0.0625,0.333333333 0.0416666667,0.166666667 0,0 Z
          -->
        </clipPath>
      </defs>
    </svg>   

    <div class="menu__row">

      <div class="menu__moving-box"></div>

      <div class="menu__column" data-link-index="0"
        @mouseenter="menuColumnMouseEnter"
        @click="visitSection"
      >
        <div class="menu__column-text"><span data-seq-text="01">首页</span></div>
      </div>


      <div class="menu__column" data-link-index="1"
        @mouseenter="menuColumnMouseEnter"
        @click="visitSection"
      >
        <div class="menu__column-text"><span data-seq-text="02">产品服务</span></div>
      </div>

      <div class="menu__column" data-link-index="2"
        @mouseenter="menuColumnMouseEnter"
        @click="visitSection"
      >
        <div class="menu__column-text"><span data-seq-text="03">案例</span></div>
      </div>
      
      <!-- -2 -->
      <div class="menu__column" data-link-index="3"
        @mouseenter="menuColumnMouseEnter"
        @click="visitSection"
      >
        <div class="menu__column-text"><span data-seq-text="04">合作伙伴</span></div>
      </div>

      <div class="menu__column" data-link-index="4"
        @mouseenter="menuColumnMouseEnter"
        @click="visitSection"
      >
        <div class="menu__column-text"><span data-seq-text="05">关于</span></div>
      </div>

      <!-- -1 -->
      <div class="menu__column" data-link-index="5" 
        @mouseenter="menuColumnMouseEnter"
        @click="visitSection"
      >
        <div class="menu__column-text"><span data-seq-text="06">联系我们</span></div>
      </div>
      
    </div>
  </div>  
</template>

<script>

import {
  TweenMax,
  CSSPlugin,
  AttrPlugin
}  from "gsap/all";

const plugins = [ CSSPlugin, AttrPlugin ];

import { getAboutEnterAnimation } from '../animations/about.js';

import state from '../state.js';

// const popups = ['about', 'recruitment', 'caseDetail'];
const popups = ['caseDetail'];

function closePopups(activePopup, appVm) {
  const candidates = activePopup ? popups.filter(val => val != activePopup) : popups;
  candidates.forEach(val => {
    appVm.$refs[val] && appVm.$refs[val][val + 'EnterAnimation'].reverse();
  })
}

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export default {

  mounted() {
    
    this.movingBox = this.$el.querySelector('.menu__moving-box');
    this.clipPath = this.$el.querySelector('#menu-clip-path path');

    this.clipPathStartPath = 'M0,0 L1,0 C1,0.166204132 1,0.33271662 1,0.499537465 C1,0.66635831 1,0.833179155 1,1 L0,1 C0,0.833024977 0,0.666204132 0,0.499537465 C0,0.332870799 0,0.16635831 0,0 Z';
    this.clipPathEndPath = 'M0,0 L1,0 C0.958333333,0.166204132 0.9375,0.33271662 0.9375,0.499537465 C0.9375,0.66635831 0.958333333,0.833179155 1,1 L0,1 C0.0416666667,0.833333333 0.0625,0.666666667 0.0625,0.5 C0.0625,0.333333333 0.0416666667,0.166666667 0,0 Z';

  },

  data() {
    return {
      movingBox: null
    }
  },

  methods: {

    visitSection(e) {

      if (state.lock.menuItem) return;
      state.lock.menuItem = 1;

      const appVm = this.$parent;

      // close menu no matter what
      appVm.closeMenu();

      const indexIn = e.currentTarget.dataset.linkIndex;

      if (!indexIn) return;

      /*

      // specific for none-slide section
      if (indexIn < 0) {
                       
        // for about page                
        if (indexIn == -1) {
          // close popups
          closePopups('about', appVm);

          if (appVm.$refs.about) {
            // about component exists
            appVm.$refs.about.aboutEnterAnimation.play();
          } else {
            // load about component for the first time
            appVm.isAboutLoaded = 1;
          }
        }

        // for recruitment page
        if (indexIn == -2) {
          // close popups
          closePopups('recruitment', appVm);

          if (appVm.$refs.recruitment) {
            appVm.$refs.recruitment.recruitmentEnterAnimation.play();
          } else {
            appVm.isRecruitmentLoaded = 1;
          }

        }

        return;
        
      }

      */

      // for slide section

      closePopups(false, appVm);

      const slider = appVm.$refs.routerView.slider;

      // close about if it is present
      // appVm.$refs.about && 
      // appVm.$refs.about.aboutEnterAnimation.progress() == 1 && 
      // appVm.$refs.about.aboutEnterAnimation.reverse();
      
      if (indexIn == slider.current) {
        // same slide clicked
      } else {
        // a different slide clicked
        slider.lock = true;
        slider.animateSlide(+indexIn, slider.current)
          .then(function() {
            slider.current = +indexIn;
            slider.lock = false;
          })
      }

    },

    menuColumnMouseEnter(e) {

      const vm = this;

      this.textColor = this.textColor || $(e.target).find('.menu__column-text').css('color');

      // shit text color
      TweenMax.to($(e.target).siblings().find('.menu__column-text'), .5, {
        color: this.textColor
      });
      TweenMax.to(e.target.querySelector('.menu__column-text'), .5, {
        color: '#fff'
      });
      
      // shift border color
      TweenMax.to([
        $(e.target).siblings('.menu__column')
      ], .5, {
        borderColor: '#f1f1f1'
      });

      const $targetNextEl = $(e.target).next();
      $targetNextEl.length > 0 &&
        TweenMax.to([
          e.target,
          $(e.target).next()
        ], .5, {
          borderColor: 'transparent'
        });

      TweenMax.killTweensOf(this.movingBox);

      // restore clip path
      $(this.clipPath).attr('d', this.clipPathStartPath);

      // moving cliper dom
      TweenMax.to(this.movingBox, 1.25, {
        x: e.target.offsetLeft,
        ease: Power4.easeOut
      });

      if (isSafari) {
        $(this.clipPath).attr('d', this.clipPathEndPath);
      } else {
        // morph clip-path
        TweenMax.killTweensOf(this.clipPath);
        TweenMax
        .to(this.clipPath, 1.5 ,{
          delay: .85,
          attr: {
            d: this.clipPathEndPath
          },
        });
      }

      

      if (!this.movingBoxInited) {
        TweenMax.to(this.movingBox, 0.3, {
          autoAlpha: 1,
          onComplete: function() {
            vm.movingBoxInited = 1
          }
        });
      }
      
    }
    
  }
}
</script>

<style lang="postcss">

.menu {

  position: absolute;
  /* z-index: var(--menu-z-index); */
  
  width: var(--menu-width);
  height: 100%;
  left: var(--dock-width);
  top: 0;
  
  z-index: -1;
  
  overflow: hidden;

  svg {
    width: 0;
    height: 0;
    position: absolute;
  }

  &__row {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__moving-box {

    position: absolute;
    width: var(--menu-column-width);
    height: 100vh;
    will-change: transform, opacity;
    transform: translate3d(-50%, 0, 0);
    top: 0;
    background: var(--primary-red);
    clip-path: url(#menu-clip-path);
    visibility: hidden;
    opacity: 0;
  }

  &__column {

    cursor: pointer;

    position: relative;

    
    width: var(--menu-column-width);
    height: 100%;

    flex: 1 1 var(--menu-column-width);

    display: flex;
  
    visibility: hidden;
  
    &:not(:first-of-type) {
      border-left: solid 1px #F1F1F1; 
    }
  }

  &__column-text {

    position: absolute;

    display: flex;
    align-items: center;

    width: 100%;
    min-height: 25.6rem;

    left: 50%;
    transform: translateX(-50%);

    top: 38.14%;

    font-size: calc(48 * 100vw / 1920);
    color: var(--primary-black);
    
    font-weight:600;

    writing-mode: vertical-rl;
    text-orientation: upright;

    span {
      position: relative;

      letter-spacing: calc(8 * 100vw / 1920);

      &::before {
        letter-spacing: 0;
        font-family: 'Oxanium Medium', sans-serif;
        writing-mode: horizontal-tb;
        
        position: absolute;
        content: attr(data-seq-text);
        top: 2.5%;
        right: 110%;
        opacity: .6;
        font-size: calc(16 * 100vw / 1920);
      }
    }
  }
}
</style>