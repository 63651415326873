<template>
  <div class="about" data-back-root="about">

    <svg>
      <defs>
        <clipPath id="button-clip-2" clipPathUnits="objectBoundingBox">
          <path d="M0.0290679967,0 C0.00968933223,0.166666667 -1.33226763e-15,0.333333333 -1.33226763e-15,0.5 C-1.33226763e-15,0.666666667 0.00968933223,0.833333333 0.0290679967,1 L1,1 L1,0 L0.0290679967,0 Z" id="Path" transform="translate(0.500000, 0.500000) scale(-1, 1) rotate(-360.000000) translate(-0.500000, -0.500000) "></path>
        </clipPath>
      </defs>
    </svg>

    <div class="about-content">
      <div class="about__title">{{ about_title }}</div>
      <div class="about__description">{{ about_subtitle }}
        <div class="about__description-deco">
          <div>
            <svg viewBox="0 0 37 87">
              <use class="about__description-deco-unit" xlink:href="#logo-frag-3"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="about__text">{{ about_description_1 }}</div>
      <div class="about__text">{{ about_description_2 }}</div>

      <a href="#" class="platform__enter platform__enter-2" @click.prevent="linkPlatform" style="margin-top: 10%">
          <span class="platform__enter-text">前往平台</span>
          <span class="platform__enter-deco">
            <div>
              <svg viewBox="0 0 41 87">
                <use class="platform__enter-deco-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </div>
            <div>
              <svg viewBox="0 0 41 87">
                <use class="platform__enter-deco-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </div>
            <div>
              <svg viewBox="0 0 41 87">
                <use class="platform__enter-deco-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </div>
            <div>
              <svg viewBox="0 0 41 87">
                <use class="platform__enter-deco-unit" xlink:href="#logo-frag-2"></use>
              </svg>
            </div>
          </span>
        </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      about_title: '',
      about_subtitle: '',
      about_description_1: '',
      about_description_2: ''  
    }
  },
  methods: {
    linkPlatform() {
      window.location.href = 'https://e.trendimedia.com';
    }
  },
  created() {
    
    const propHost = this.$root.$children[0];
    
    this.about_title = propHost.about_title
    this.about_subtitle = propHost.about_subtitle;
    this.about_description_1 = propHost.about_description_1;
    this.about_description_2 = propHost.about_description_2;

  },
  mounted() {
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (isChrome) {
      $(this.$el.querySelector('.platform__enter-2')).addClass('platform__enter-2--clip');
    }
  }
}
</script>

<style lang="postcss">
.about {
  
  position: absolute;
  
  width: var(--menu-width);
  height: 100%;
  left: var(--dock-width);
  top: 0;

  /* z-index: -1;
  display: none; */
  
  &__brush {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &-order-1 {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      transform: scaleX(0);
      transform-origin: right;
      background: #eee;
    }

    &-order-2 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      transform: scaleX(0);
      transform-origin: left;
      background: #fff;
      transform: scaleX(0); 
      /* .5055 */
    }

  }

  &__title {
    font-size: calc(64 * 100vw / 1920);
    font-weight: bold;
    color: #000;
    /* margin-bottom: calc(97 * 100vw / 1920); */
    margin-bottom: 20%;
    position: relative;
    line-height: 100%;

  }
  &__description {
    font-size: calc(24 * 100vw / 1920);
    font-weight: bold;
    color: #000;
    line-height: calc(36 * 100vw / 1920);
    position: relative;

    &-deco {
      width: calc(18 * 100vw / 1920);
      height: calc(9 * 100vw / 1920);
      position: absolute;
      bottom: calc(100% + calc(18 * 100vw / 1920));
      left: 0;

      & > div {
        width: calc(9 * 100vw / 1920);
        height: calc(18 * 100vw / 1920);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        

        svg {
          width: 100%;
          
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  &__text {
    /* margin-top: calc(48 * 100vw / 1920); */
    margin-top: 5%;
    font-size: calc(16 * 100vw / 1920);
    color: var(--primary-black);
    line-height: calc(30 * 100vw / 1920);
    border-top: 1px solid #e7e7e7;
    padding-top: calc(24 * 100vw / 1920)
  }

  &__go-back-wrap {
    position: absolute;
    top: 6.48%;
    left: 10.44%;
  }
}

.about-content {
  position: absolute;
  width: 24.88%;
  top: 17.59%;
  /* left: 10%; */
  left: 4.5%;
  
}

.platform {
  &__enter-2 {
    

    &--clip {
      clip-path: url(#button-clip-2);
    }
  }
}

use.about__description-deco-unit {
  fill: var(--primary-red);
}


</style>