import { 
  TweenMax,
  AttrPlugin,
  CSSPlugin,
  TimelineLite,
  Power2
} from "gsap/all";

const activated = [
  TweenMax,
  AttrPlugin,
  CSSPlugin
];

// import critical css
import './styles/critical.postcss';

// global polyfill
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

// vue
import Vue from 'vue';
import VueRouter from 'vue-router';

// vue app
import App from './App.vue';
import Index from './router_views/Index.vue';

// page-entring logo animation
import AnimateLogoEntering from './animations/logo.js';

import state from './state.js';

new AnimateLogoEntering('.full-logo', function() {

  getAppData()
    .then(initApp)

  

  const tl = new TimelineLite;
  tl
    .to('.preload-wrap', .5, {
      delay: 1.25,
      autoAlpha: 0,
      ease: Power2.easeIn
    })
    .to('.preload-wrap', 0, {
      display: 'none'
    })
    .from('#app', 1, {
      scale: 1.075,
      ease: Power2.easeInOut,
      clearProps: 'transform'
    }, '-=.35')

});

function initApp(props = {}) {

  new Vue({
    el: '#app',
    router,
    render: h => h(App, {
      props
    }),
    mounted () {
      // You'll need this for renderAfterDocumentEvent.
      document.dispatchEvent(new Event('render-event'));
    }
  });
}

function getAppData() {

  return new Promise(function(resolve, reject) {
    const url = state.host + '/trendimedia_wordpress/wp-json/trendi/v1/composites';
    fetch(url, {  
        method: 'GET',
        mode: 'cors'
    })
      .then(res => res.json())
      .then(res => {
        resolve(res[0])
      })
      .catch(e => reject(e))
  })
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Index,
    meta: {
      title: 'TrendiMedia'
    }
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
});